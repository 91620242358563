import React, { useEffect, useRef } from 'react';
import styles from '../ReviewTab.module.css';
import { MessageRole, SuggestChatMessage } from '../../../../models/chat';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { Image } from '@fluentui/react-components';
import {
  sendSuggestChatMessage,
  setchatSuggestMessages,
} from '../../../../store/reviewSlice';
import { v4 as uuidv4 } from 'uuid';
import BotChating from '../../../shared/botChating/BotChating';
import { PosthogEventsNames } from './const';
import { usePostHog } from 'posthog-js/react';

const SuggestChat = ({
  onApply,
  onClose,
  current_clause,
  ai_clause,
  sessionId,
  stepSessionId,
}: any) => {
  const [value, setValue] = React.useState('');
  const posthog = usePostHog();
  const bottomRef = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  let chatSuggestMessages = useSelector(
    (state: RootState) => state.review.chatSuggestMessages
  );
  let documentId = useSelector((state: RootState) => state.office.documentId);
  let chatingLoading = useSelector(
    (state: RootState) => state.review.chatingLoading
  );
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const user_id = useSelector((state: RootState) => state.socket.user_id);
  const onMessageSend = (input: string = undefined) => {
    console.log(chatSuggestMessages);
    if (isConnected && (value !== '' || input)) {
      dispatch(
        sendSuggestChatMessage({
          //another api for alternativeSendMessage
          newMessage: {
            id: uuidv4(),
            message: input ? input : value,
            role: MessageRole.User,
            isChunk: false,
          },
          clause: current_clause,
          ai_clause: ai_clause,
        })
      );
      posthog.capture(
        PosthogEventsNames.ReviewTabSuggestAlternativeChatUserAnswerSent,
        {
          distinct_id: user_id, // Use a unique identifier for the user/session
          time: new Date().toISOString(),
          session_id: sessionId,
          stepSessionId: stepSessionId,
          tab_name: 'review',
          source: 'word',
          review_step: 'clauses',
          answer: value,
          documentId: documentId,
          chat_type: 'suggest_alternative',
        }
      );
      setValue('');
    }
  };
  useEffect(() => {
    if (isConnected && chatSuggestMessages.length == 0) {
      const newMessage: SuggestChatMessage = {
        id: uuidv4(),
        role: MessageRole.Assistant,
        message: 'How do you want to revise this clause?',
        isChunk: false,
        is_question: false,
        is_suggestion_message: false,
        stepSessionId: stepSessionId,
        time: new Date().toISOString(),
        user_id: user_id,
      };
      dispatch(setchatSuggestMessages([newMessage])); //save message in alternative messages
    }
  }, [isConnected]);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatSuggestMessages]);
  return (
    <div className={styles.suggestPup}>
      <div
        className={styles.closePopButton}
        onClick={() => {
          onClose();
        }}
      >
        <Image src="/assets/crosscircle.png" />
      </div>
      <BotChating
        loading={chatingLoading}
        onYes={() => {
          onMessageSend('yes');
        }}
        onNo={() => {
          onMessageSend('no');
        }}
        onApply={() => {
          //here update suggest of the clause if it is main or link one
          //then return to clauses page
          onApply();
        }}
        onRegenrate={() => {
          onMessageSend('regenrate');
        }}
        onMessageSend={() => {
          onMessageSend(value);
        }}
        chatMessages={chatSuggestMessages.map(c => {
          return {
            ...c,
            is_yes_no: c.is_question,
            is_apply_regenrate: c.is_suggestion_message,
          };
        })}
        value={value}
        setValue={setValue}
        height="80vh"
        classNameCnt={styles.chatAltCnt}
        messageBoxHeight='100px'
        messageBoxWidth='100%'
      />
    </div>
  );
};

export default SuggestChat;
