import * as React from 'react';
import styles from './ReviewList.module.css';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@fluentui/react-components';

import { AppDispatch, RootState } from '../../../../store/store';
import {
  clearSuggestions,
  setIsAnalyzing,
} from '../../../../store/officeSlice';
import { sendMessage } from '../../../../store/socketSlice';
import { setReviewProgress } from '../../../../store/reviewSlice';
import { usePostHog } from 'posthog-js/react';
import { v4 as uuidv4 } from 'uuid';
import { PosthogEventsNames } from './const';

export enum ReviewCategory {
  Unacceptable = 'Unacceptable',
  NonStandard = 'Non-Standard',
  Acceptable = 'Acceptable',
  Missing = 'Missing',
}
const steps = {
  initial: 1,
  chat: 2,
  review: 3,
  clauses: 4,
};

interface ReviewListItemProps {
  count: number;
  label: string;
  reviewCategory: ReviewCategory;
  setStepNo: React.Dispatch<React.SetStateAction<number>>;
  setReviewCategory: React.Dispatch<React.SetStateAction<ReviewCategory>>;
  reviewProgress: number;
}
const ReviewListItem = (props: ReviewListItemProps) => {
  const isAnalyzing = useSelector(
    (state: RootState) => state.office.isAnalyzing
  );
  const cx = classNames.bind(styles);
  const className = cx({
    reviewItemContainer: true,
    unacceptable: props.reviewCategory === ReviewCategory.Unacceptable,
    nonStandard: props.reviewCategory === ReviewCategory.NonStandard,
    acceptable: props.reviewCategory === ReviewCategory.Acceptable,
    missing: props.reviewCategory === ReviewCategory.Missing,
  });

  return (
    <div
      className={className}
      onClick={() => {
        if (props.count === 0) return;
        props.setReviewCategory(props.reviewCategory);
        props.setStepNo(steps.clauses);
      }}
      style={{ opacity: props.reviewProgress < 90 ? '0.5' : undefined }}
    >
      <div className={styles.counter}>{props.count}</div>
      <p>{props.label}</p>
      {/*  <div className={styles.loading}>{isAnalyzing && <Spinner />}</div> */}
    </div>
  );
};

export interface ReviewListProps {
  setStepNo: React.Dispatch<React.SetStateAction<number>>;
  setReviewCategory: React.Dispatch<React.SetStateAction<ReviewCategory>>;
  sessionId?: string;
}
export const ReviewList: React.FC<ReviewListProps> = ({
  setStepNo,
  setReviewCategory,
  sessionId,
}) => {
  const posthog = usePostHog();
  let suggestions = useSelector((state: RootState) => state.office.suggestions);
  let isAnalyzing = useSelector((state: RootState) => state.office.isAnalyzing);
  let reviewProgress = useSelector(
    (state: RootState) => state.review.reviewProgress
  );

  const [stepSessionId, setStepSessionId] = React.useState(null);
  let user_id = useSelector((state: RootState) => state.socket.user_id);

  const documentId = useSelector((state: RootState) => state.office.documentId);
  const party = useSelector((state: RootState) => state.review.party);
  const additionalInstructions = useSelector(
    (state: RootState) => state.review.additional_instructions
  );
  suggestions = suggestions.filter(x => x.user_suggestion_action !== 'deleted');
  const dispatch = useDispatch<AppDispatch>();
  React.useEffect(() => {
    if (!isAnalyzing && suggestions.length === 0) {
      dispatch(setIsAnalyzing(true));
      dispatch(clearSuggestions());

      dispatch(
        sendMessage({
          action: 'DocumentReviewRequestV2',
          user_document_id: documentId,
          party: '',
          additional_instructions: '',
        })
      );
    }
  }, [isAnalyzing, suggestions]);
  React.useEffect(() => {
    let interval;

    // Start the interval
    if (reviewProgress == 0) {
      interval = setInterval(() => {
        dispatch((dispatch, getState) => {
          const currentProgress = getState().review.reviewProgress;
          if (currentProgress >= 80) {
            clearInterval(interval);
            console.log('Interval cleared.');
          } else {
            dispatch(setReviewProgress(currentProgress + 10));
          }
        });
      }, 16000);
    }

    // Cleanup function to clear the interval
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);
  React.useEffect(() => {
    // Capture the event when the tab is used

    let id = uuidv4();
    if (!stepSessionId && posthog && user_id && sessionId) {
      setStepSessionId(id);
      posthog.capture(PosthogEventsNames.ReviewTabListOpened, {
        distinct_id: user_id, // Use a unique identifier for the user/session
        time: new Date().toISOString(),
        session_id: sessionId,
        stepSessionId: id,
        tab_name: 'review',
        source: 'word',
        review_step: 'list',
        documentId: documentId,
      });
      return () => {
        posthog.capture(PosthogEventsNames.ReviewTabListClosed, {
          distinct_id: user_id, // Use a unique identifier for the user/session
          time: new Date().toISOString(),
          session_id: sessionId,
          tab_name: 'review',
          source: 'word',
          review_step: 'list',
          stepSessionId: id,
          documentId: documentId,
        });
      };
    } else {
      return () => {};
    }
  }, [sessionId]);
  return (
    <div className={styles.container}>
      <ReviewListItem
        setReviewCategory={setReviewCategory}
        setStepNo={setStepNo}
        count={suggestions.filter(x => x.category === 'Unacceptable').length}
        label="Unacceptable"
        reviewCategory={ReviewCategory.Unacceptable}
        reviewProgress={reviewProgress}
      />
      <ReviewListItem
        setReviewCategory={setReviewCategory}
        setStepNo={setStepNo}
        count={suggestions.filter(x => x.category === 'Non-Standard').length}
        label="Non-Standard"
        reviewCategory={ReviewCategory.NonStandard}
        reviewProgress={reviewProgress}
      />
      <ReviewListItem
        reviewProgress={reviewProgress}
        setReviewCategory={setReviewCategory}
        setStepNo={setStepNo}
        count={suggestions.filter(x => x.category === 'Acceptable').length}
        label="Acceptable"
        reviewCategory={ReviewCategory.Acceptable}
      />
      <ReviewListItem
        reviewProgress={reviewProgress}
        setReviewCategory={setReviewCategory}
        setStepNo={setStepNo}
        count={suggestions.filter(x => x.category === 'Missing').length}
        label="Missing"
        reviewCategory={ReviewCategory.Missing}
      />
    </div>
  );
};
