import React, { useState, useEffect } from 'react';
import styles from './Summary.module.css';
import { AppDispatch, RootState } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { summarizeReviewDocument } from '../../../store/documentSlice';
import { MarkdownRenderer } from '../../shared/misc/MarkdownRenderer';
import { Image, Spinner } from '@fluentui/react-components';
import {
  Checkmark16Regular,
  Copy16Regular,
  ThumbDislike16Regular,
  ThumbLike16Regular,
} from '@fluentui/react-icons';
import Loading from '../../shared/loading/Loading';
import { sendFeedback } from '../../../store/socketSlice';
import { RequestEnum } from '../../../models/base';
import { UserFeedbackRequest } from '../../../models/feedback';
import { FeedbackModal } from 'qanoonisharedpackage';

const EmailSummary = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loadReviewSummary = useSelector(
    (state: RootState) => state.document.loadReviewSummary
  );
  const initial_document_state = useSelector(
    (state: RootState) => state.document.initial_document_state
  );
  const review_summary = useSelector(
    (state: RootState) => state.document.review_summary
  );
  const [isLikeModalOpen, setIsLikeModalOpen] = useState(false);
  const [isDislikeModalOpen, setIsDislikeModalOpen] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState<string>('');
  const [isToastVisible, setIsToasVisible] = useState(false);
  const showNotification = () => {
    const id = new Date().getTime(); // Unique id based on timestamp
    setIsToasVisible(true);
    // Automatically remove the notification after 3 seconds
    setTimeout(() => {
      setIsToasVisible(false);
    }, 1500);
  };
  const sendFeedbackGet = (feedbackType: 'thumbs_up' | 'thumbs_down') => {
    const request: UserFeedbackRequest = {
      action: RequestEnum.UserFeedbackRequest,
      feature_type: 'Review_Email_Summary',
      feature_ref_id: null,
      feedback: feedbackType,
      trace_id: null,
      note: feedbackContent,
    };
    dispatch(sendFeedback(request));
    setIsLikeModalOpen(false);
    setIsDislikeModalOpen(false);
    setFeedbackContent('');
  };
  if (loadReviewSummary == true) return <Loading />;
  if (!review_summary)
    return (
      <div className={styles.createBtnCnt}>
        <div
          className={styles.createBtn}
          onClick={() => {
            dispatch(
              summarizeReviewDocument({
                initial_document_state: initial_document_state,
              })
            );
          }}
        >
          Create a Draft Email
        </div>
      </div>
    );
  return (
    <div className={styles.Emailcnt}>
      <div className={styles.Emailtitle}>Here is a reply email:</div>

      <div className={styles.EmailBody}>
        <div className={styles.summaryarea}>
          <MarkdownRenderer markdown={review_summary} />
          <div className={styles.likeCnt} style={{ flexGrow: '0' }}>
            <button
              title="Regenrate"
              className={styles.summIcon}
              //disabled={}
              onClick={() => {
                dispatch(
                  summarizeReviewDocument({
                    initial_document_state: initial_document_state,
                  })
                );
              }}
            >
              {loadReviewSummary === 'streaming' ? (
                <Spinner size="extra-tiny" />
              ) : (
                <Image src="/assets/regenerate.svg" />
              )}
            </button>

            <button
              title="Copy text"
              className={styles.summIcon}
              onClick={async () => {
                await navigator.clipboard.writeText(review_summary);
                showNotification();
              }}
            >
              {isToastVisible ? <Checkmark16Regular /> : <Copy16Regular />}
            </button>
            <button
              title="Like"
              className={styles.summIcon}
              onClick={() => setIsLikeModalOpen(true)}
            >
              <ThumbLike16Regular />
            </button>
            <button
              title="Dislike"
              className={styles.summIcon}
              onClick={() => setIsDislikeModalOpen(true)}
            >
              <ThumbDislike16Regular />
            </button>
          </div>
        </div>

        <FeedbackModal
          isDislikeModalOpen={isDislikeModalOpen}
          isLikeModalOpen={isLikeModalOpen}
          setFeedbackContent={setFeedbackContent}
          setIsDislikeModalOpen={setIsDislikeModalOpen}
          setIsLikeModalOpen={setIsLikeModalOpen}
          sendFeedbackGet={sendFeedbackGet}
          feedbackContent={feedbackContent}
        />
      </div>
    </div>
  );
};

export default EmailSummary;
