import {
  makeStyles,
  shorthands,
  Spinner,
  Tooltip,
} from '@fluentui/react-components';
import React, { useState } from 'react';
import { AppDispatch, RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { setActionsTab } from '../../store/officeSlice';
import { ActionsTabs } from '../features/review/components/const';
import { UpgradeModal } from 'qanoonisharedpackage';

const ActionsPage = () => {
  const styles = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalType, setModalType] = useState<'review' | 'draft'>('review');
  const isEmptyDocument = useSelector(
    (state: RootState) => state.office.isEmptyDocument
  );
  const isAnalyzing = useSelector(
    (state: RootState) => state.office.isAnalyzing
  );
  const has_more_reviews = useSelector(
    (state: RootState) => state.activity.has_more_reviews
  );
  const has_more_drafts = useSelector(
    (state: RootState) => state.activity.has_more_drafts
  );
  const displayGlobalChangesFlagValue = useSelector(
    (state: RootState) => state.featureFlag.displayGlobalChangesFlagValue
  );
  const displayDraftFlagValue = useSelector(
    (state: RootState) => state.featureFlag.displayDraftFlagValue
  );
  return (
    <div className={styles.pageCnt}>
      <UpgradeModal
        isOpen={isOpenModal}
        type={modalType}
        onClose={() => {
          setIsOpenModal(false);
        }}
        contactSales={() => {
          window.open(process.env.SUBSCRIPTION_FORM_URL, '_blank');
        }}
      />
      {isEmptyDocument && displayDraftFlagValue && (
        <div
          className={styles.actionsBtn}
          onClick={() => {
            if (!has_more_drafts) {
              setIsOpenModal(true);
              setModalType('draft');
            } else {
              dispatch(setActionsTab(ActionsTabs.draft));
            }
          }}
        >
          Draft a New Document
        </div>
      )}
      {!isEmptyDocument && (
        <div
          className={styles.actionsBtn}
          onClick={() => {
            console.log(has_more_reviews);
            if (!has_more_reviews) {
              setIsOpenModal(true);
              setModalType('review');
            } else {
              dispatch(setActionsTab(ActionsTabs.review));
            }
          }}
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>Review a Document</div>
          {isAnalyzing && (
            <Tooltip
              content="Document Review is in Progress"
              relationship="label"
            >
              <Spinner size="tiny" />
            </Tooltip>
          )}
        </div>
      )}
      {displayGlobalChangesFlagValue && (
        <div
          className={styles.actionsBtn}
          onClick={() => {
            dispatch(setActionsTab(ActionsTabs.globalChanges));
          }}
        >
          Make Global Changes
        </div>
      )}
    </div>
  );
};

export default ActionsPage;

const useStyles = makeStyles({
  pageCnt: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('8px'),
    alignItems: 'center',
    marginTop: '20vh',
    width: '100%',
  },
  actionsBtn: {
    width: '230px',
    cursor: 'pointer',
    height: '50px',
    backgroundColor: 'white',
    ...shorthands.border('1px', 'solid', '#8B8B8B'),
    textAlign: 'center',
    lineHeight: '48px',
    ...shorthands.borderRadius('5px'),
    '&:hover': {
      backgroundColor: '#eee',
      scale: '1.02',
    },
  },
});
