import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import styles from './Clauses.module.css';
import { AppDispatch, RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import diff from 'diff-match-patch';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames/bind';
import {
  applySuggestion,
  scrollToSuggestion,
  setAllSuggestions,
} from '../../../../store/officeSlice';
import { OFFICE_ENCODING_COMMENT } from '../../../../helper/office';
import {
  setchatSuggestMessages,
  setViewHeader,
} from '../../../../store/reviewSlice';
import { DocumentSuggestion, RelatedUpdate } from '../../../../models/review';
import SuggestChat from './SuggestChat';
import { PosthogEventsNames, ReviewSteps } from './const';
import { ReviewCategory } from './ReviewList';
import {
  insertTextAfterClause,
  removeClauseByText,
} from '../../../../helper/missingClause';
import { Spinner } from '@fluentui/react-components';
import { Dismiss16Regular, Edit16Regular } from '@fluentui/react-icons';
import { TextField } from '@fluentui/react';
import { ConfirmMessage } from './ConfirmMessage';
import { usePostHog } from 'posthog-js/react';

//refer to the headings for the clauses labels
const clauseActions = {
  dismiss: 'dismiss',
  keep: 'keep',
  insert: 'insert',
};
export const Clauses = ({
  reviewCategory,
  setStepNo,
  savedActions,
  setSavedActions,
  savedLinkedActions,
  setSavedLinkedActions,
  suggestions,
  sessionId,
}: {
  reviewCategory: ReviewCategory;
  setStepNo: any;
  savedActions: any;
  setSavedActions: any;
  savedLinkedActions: any;
  setSavedLinkedActions: any;
  suggestions: DocumentSuggestion[];
  sessionId: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const posthog = usePostHog();
  const [showPop, setShowPop] = useState(false);
  const [suggestAlt, setSuggestAlt] = useState(false);
  const [suggestAlt2, setSuggestAlt2] = useState<any>(false);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [newSuggest, setNewSuggest] = useState(undefined);
  const [linkNewSuggest, setLinkNewSuggest] = useState({}); //state for changes by link clause index
  const [clauseAction, setClauseAction] = useState(undefined);
  const [linkClauseAction, setLinkClauseAction] = useState({}); //state action by link clause index
  const [isEditableClause, setisEditableClause] = useState(false);
  const [isEditableClauseLinks, setisEditableClauseLinks] = useState({});
  const [stepSessionId, setStepSessionId] = React.useState(null);
  const sanitizedParagraphs = useSelector(
    (state: RootState) => state.office.sanitizedParagraphs
  );
  let user_id = useSelector((state: RootState) => state.socket.user_id);
  const documentId = useSelector((state: RootState) => state.office.documentId);
  let chatSuggestMessages = useSelector(
    (state: RootState) => state.review.chatSuggestMessages
  );
  const [loadInsertion, setLoadInsertion] = useState(false);
  const filteredSuggestions = React.useMemo(() => {
    return suggestions.filter(x => {
      if (x.category === reviewCategory) {
        return true;
      } else return false;
    });
  }, [suggestions]);

  const [currentSuggestion, setCurrentSuggestion] =
    React.useState<DocumentSuggestion>(filteredSuggestions[0]);
  const [currentSuggestionIndex, setCurrentSuggestionIndex] =
    React.useState<number>(0);
  const clauseRef = useRef<HTMLDivElement | null>(null);

  const currentSuggestionPreview = React.useMemo(() => {
    if (currentSuggestion) {
      let current_value = currentSuggestion.original_text ?? '';
      let applying_value = newSuggest
        ? newSuggest
        : currentSuggestion.suggested_text
        ? currentSuggestion.suggested_text
        : '';
      const dmp = new diff.diff_match_patch();
      let diffs;
      if (reviewCategory == ReviewCategory.Acceptable && applying_value == '') {
        return '';
      } else {
        diffs = dmp.diff_main(
          current_value.replace(OFFICE_ENCODING_COMMENT, ''),
          applying_value.replace(OFFICE_ENCODING_COMMENT, '')
        );
      }

      dmp.diff_cleanupSemantic(diffs);
      return dmp.diff_prettyHtml(diffs);
    } else return '';
  }, [newSuggest, currentSuggestion]);

  const currentSuggestionPreviewLink = React.useMemo(() => {
    let output = {};
    if (currentSuggestion?.related_updates?.length > 0) {
      for (let linkIndex in Object.keys(currentSuggestion.related_updates)) {
        let suggest = currentSuggestion.related_updates[linkIndex];
        let applying_value = linkNewSuggest[linkIndex]
          ? linkNewSuggest[linkIndex]
          : suggest.suggested_text ?? '';
        const dmp = new diff.diff_match_patch();
        const diffs = dmp.diff_main(
          suggest.original_text.replace(OFFICE_ENCODING_COMMENT, ''),
          applying_value.replace(OFFICE_ENCODING_COMMENT, '')
        );
        dmp.diff_cleanupSemantic(diffs);
        output[linkIndex] = dmp.diff_prettyHtml(diffs);
      }
      return output;
    } else return {};
  }, [currentSuggestion, linkNewSuggest]);
  const cx = classNames.bind(styles);
  const className = cx({
    clauseText: true,
    unacceptable: reviewCategory === ReviewCategory.Unacceptable,
    nonStandard: reviewCategory === ReviewCategory.NonStandard,
    acceptable: reviewCategory === ReviewCategory.Acceptable,
    missing: reviewCategory === ReviewCategory.Missing,
  });
  const ScrollToSuggestion = suggestion => {
    let current_Suggest = { ...suggestion };
    if (currentSuggestion) {
      if (reviewCategory == ReviewCategory.Missing) {
        current_Suggest.original_text =
          current_Suggest.paragraph_before_missing_clause
            .trim()
            .replace(/\s+/g, ' ')
            .split(' ')
            .splice(1)
            .join(' ');
      }
      dispatch(scrollToSuggestion(current_Suggest));
    }
  };
  const navigateToNextSuggestion = () => {
    if (currentSuggestionIndex < filteredSuggestions.length - 1) {
      const suggestion = filteredSuggestions[currentSuggestionIndex + 1];
      ScrollToSuggestion(suggestion);
      setCurrentSuggestion(suggestion);
      setCurrentSuggestionIndex(currentSuggestionIndex + 1);
    }
  };

  const navigateToPreviousSuggestion = () => {
    if (currentSuggestionIndex > 0) {
      const suggestion = filteredSuggestions[currentSuggestionIndex - 1];
      ScrollToSuggestion(suggestion);
      setCurrentSuggestion(suggestion);
      setCurrentSuggestionIndex(currentSuggestionIndex - 1);
    }
  };
  const cleanFlags = () => {
    setClauseAction(undefined);
    setNewSuggest(undefined);
    setLinkClauseAction({});
    setLinkNewSuggest({});
    setisEditableClause(false);
    setisEditableClauseLinks({});
  };

  useEffect(() => {
    ScrollToSuggestion(currentSuggestion);
  }, [currentSuggestion]);

  useEffect(() => {
    //using the  original text find the heading of the clause from the document sections,
    if (savedActions[currentSuggestionIndex + reviewCategory]) {
      setClauseAction(savedActions[currentSuggestionIndex + reviewCategory]);
    }
    if (currentSuggestion?.related_updates) {
      for (let linkIndex in Object.keys(currentSuggestion.related_updates)) {
        if (
          savedLinkedActions[
            currentSuggestionIndex + reviewCategory + linkIndex
          ]
        ) {
          setLinkClauseAction(prev => {
            return {
              ...prev,
              [linkIndex]:
                savedLinkedActions[
                  currentSuggestionIndex + reviewCategory + linkIndex
                ],
            };
          });
        }
      }
    }
  }, [currentSuggestion]);

  if (!currentSuggestion) {
    return null;
  }

  useEffect(() => {
    dispatch(setViewHeader(false));
    return () => {
      dispatch(setViewHeader(true));
    };
  }, []);
  useEffect(() => {
    // Capture the event when the tab is used
    let id = uuidv4();
    if (!stepSessionId && posthog && user_id && sessionId) {
      setStepSessionId(id);
      posthog.capture(PosthogEventsNames.ReviewTabClausesStepOpened, {
        distinct_id: user_id, // Use a unique identifier for the user/session
        time: new Date().toISOString(),
        session_id: sessionId,
        stepSessionId: id,
        tab_name: 'review',
        source: 'word',
        review_step: 'clauses',
        documentId: documentId,
        category: reviewCategory,
        suggestions_category_count: filteredSuggestions.length,
      });
      return () => {
        posthog.capture(PosthogEventsNames.ReviewTabClausesStepClosed, {
          distinct_id: user_id, // Use a unique identifier for the user/session
          time: new Date().toISOString(),
          session_id: sessionId,
          tab_name: 'review',
          source: 'word',
          review_step: 'clauses',
          stepSessionId: id,
          documentId: documentId,
          category: reviewCategory,
          suggestions_category_count: filteredSuggestions.length,
        });
      };
    } else {
      return () => {};
    }
  }, [sessionId]);

  if (showPop)
    return (
      <SuggestChat
        onApply={() => {
          //update current suggestion based on alt1 (normal clause) or alt2 (link clause)
          // setnewsuggest to latest message in suggest
          let suggest = chatSuggestMessages[
            chatSuggestMessages.length - 1
          ].message.replace('Suggestion:', '');
          if (suggestAlt) {
            setNewSuggest(suggest);
          }
          if (suggestAlt2) {
            setLinkNewSuggest(prev => {
              return { ...prev, [suggestAlt2]: suggest };
            });
          }
          setShowPop(false);
          setSuggestAlt(false);
          setSuggestAlt2(false);
        }}
        onClose={() => {
          setShowPop(false);
          setSuggestAlt(false);
          setSuggestAlt2(false);
        }}
        current_clause={
          suggestAlt
            ? currentSuggestion?.original_text
            : currentSuggestion?.related_updates[suggestAlt2].original_text
        }
        ai_clause={
          suggestAlt
            ? currentSuggestion?.suggested_text
            : currentSuggestion?.related_updates[suggestAlt2].suggested_text
        }
        sessionId={sessionId}
        stepSessionId={stepSessionId}
      />
    );

  return (
    <div className={styles.clauseContainer}>
      <div className={styles.clauseHeader}>
        <div
          className={styles.clausetitle}
        >{`${reviewCategory} Clause ${currentSuggestion.clause_number}`}</div>
        <div className={styles.clauseValue}></div>
        <div className={styles.nav}>
          {currentSuggestionIndex > 0 && (
            <div
              ref={clauseRef}
              className={styles.clauseNav}
              onClick={() => {
                if (clauseAction) {
                  setSavedActions(prev => {
                    return {
                      ...prev,
                      [currentSuggestionIndex + reviewCategory]: clauseAction,
                    };
                  });
                }

                setSavedLinkedActions(prev => {
                  let result = { ...prev };
                  for (let linkIndex in linkClauseAction) {
                    if (linkClauseAction[linkIndex]) {
                      result[
                        currentSuggestionIndex + reviewCategory + linkIndex
                      ] = linkClauseAction[linkIndex];
                    }
                  }
                  return result;
                });
                cleanFlags();
                navigateToPreviousSuggestion();
              }}
            >
              {'<'}
            </div>
          )}

          <div className={styles.page}>{`${currentSuggestionIndex + 1} of ${
            filteredSuggestions.length
          }`}</div>
          {currentSuggestionIndex < filteredSuggestions.length - 1 && (
            <div
              className={styles.clauseNav}
              onClick={() => {
                setSavedActions(prev => {
                  return {
                    ...prev,
                    [currentSuggestionIndex + reviewCategory]: clauseAction,
                  };
                });
                setSavedLinkedActions(prev => {
                  let result = { ...prev };
                  for (let linkIndex in linkClauseAction) {
                    if (linkClauseAction[linkIndex]) {
                      result[
                        currentSuggestionIndex + reviewCategory + linkIndex
                      ] = linkClauseAction[linkIndex];
                    }
                  }
                  return result;
                });
                cleanFlags();
                navigateToNextSuggestion();
              }}
            >
              {'>'}
            </div>
          )}
        </div>
      </div>
      <div className={styles.commentaryHeading}>Commentary:</div>
      <div
        className={
          clauseAction === clauseActions.dismiss
            ? `${className} ${styles.disabledText}`
            : className
        }
        onClick={() => {
          ScrollToSuggestion(currentSuggestion);
        }}
      >
        <div>{currentSuggestion.note}</div>

        <div>
          {currentSuggestion.linked_clauses?.length > 0 && (
            <div className={styles.linkTitle}>linked clauses:</div>
          )}
          {currentSuggestion.linked_clauses.map(c => {
            return (
              <div className={styles.linkItem} key={c}>
                {c}
              </div>
            );
          })}
        </div>
      </div>

      {![ReviewCategory.Missing].includes(reviewCategory) && (
        <button
          className={styles.rightButton}
          onClick={() => {
            //setDismiss(prev => !prev)
            ScrollToSuggestion(currentSuggestion);
            setisEditableClause(false);
            if (clauseAction === clauseActions.dismiss) {
              setClauseAction(undefined);
              setLinkClauseAction({});
            } else {
              setClauseAction(clauseActions.dismiss);
              setLinkClauseAction(_ => {
                let dismissObject = {};
                for (let index in Object.keys(
                  currentSuggestion.related_updates
                )) {
                  dismissObject[index] = clauseActions.dismiss;
                }
                return dismissObject;
              });
              posthog.capture(
                PosthogEventsNames.ReviewTabClausesStepDismissClauses,
                {
                  distinct_id: user_id, // Use a unique identifier for the user/session
                  time: new Date().toISOString(),
                  session_id: sessionId,
                  stepSessionId: stepSessionId,
                  tab_name: 'review',
                  source: 'word',
                  review_step: 'clauses',
                  clause_action: 'dismiss',
                  documentId: documentId,
                  category: reviewCategory,
                  clause_text: currentSuggestion.original_text,
                  suggested_text: currentSuggestion.suggested_text,
                  clause_type: 'original',
                }
              );
            }
          }}
        >
          {clauseAction == clauseActions.dismiss ? 'Edit' : 'Dismiss'}
        </button>
      )}
      <div className={styles.commentaryHeading}>
        Suggested Language for Clause {currentSuggestion.clause_number}
      </div>
      <div className={styles.editCnt}>
        {!clauseAction && (
          <div
            className={styles.editIcon}
            onClick={() => {
              setisEditableClause(prev => !prev);
            }}
          >
            {isEditableClause ? <Dismiss16Regular /> : <Edit16Regular />}
          </div>
        )}
        {isEditableClause ? (
          <TextField
            className={styles.commentary}
            value={newSuggest || currentSuggestion.suggested_text}
            disabled={clauseAction}
            multiline
            autoAdjustHeight
            onClick={() => {
              ScrollToSuggestion(currentSuggestion);
            }}
            onChange={(_, new_value) => {
              setNewSuggest(new_value);
            }}
            styles={{
              fieldGroup: { border: 'none' },
              field: {
                fontSize: '0.687rem',
                color: '#545454',
                resize: 'none',
                border: 'none',
              },
              root: { border: 'none', resize: 'none' },
            }}
          />
        ) : (
          <div
            className={styles.commentary}
            dangerouslySetInnerHTML={{
              __html: currentSuggestionPreview,
            }}
            onClick={() => {
              ScrollToSuggestion(currentSuggestion);
            }}
            style={
              reviewCategory == ReviewCategory.Missing
                ? { minHeight: '160px' }
                : {}
            }
          ></div>
        )}
      </div>
      <div className={styles.btnCnt}>
        <button
          className={
            [clauseActions.keep, clauseActions.insert].includes(clauseAction)
              ? styles.rightButton
              : styles.mainButton
          }
          disabled={clauseAction === clauseActions.dismiss || loadInsertion}
          onClick={() => {
            setisEditableClause(false);
            if (clauseAction) {
              setClauseAction(undefined);
              if (reviewCategory == ReviewCategory.Missing) {
                if (newSuggest) {
                  removeClauseByText(newSuggest);
                } else {
                  removeClauseByText(currentSuggestion.suggested_text);
                }
                return;
              }
            }
            if (!clauseAction) {
              setClauseAction(clauseActions.keep);
              posthog.capture(
                PosthogEventsNames.ReviewTabClausesStepKeepExistingClauses,
                {
                  distinct_id: user_id, // Use a unique identifier for the user/session
                  time: new Date().toISOString(),
                  session_id: sessionId,
                  stepSessionId: stepSessionId,
                  tab_name: 'review',
                  source: 'word',
                  review_step: 'clauses',
                  clause_action: 'keep',
                  documentId: documentId,
                  category: reviewCategory,
                  clause_text: currentSuggestion.original_text,
                  suggested_text: currentSuggestion.suggested_text,
                  clause_type: 'original',
                }
              );
            }
          }}
        >
          {clauseAction ? (
            loadInsertion ? (
              <Spinner size="extra-tiny" />
            ) : (
              'Edit'
            )
          ) : reviewCategory == ReviewCategory.Missing ? (
            'Ignore'
          ) : (
            'Keep Existing'
          )}
        </button>
        {![clauseActions.insert, clauseActions.keep].includes(clauseAction) && (
          <button
            className={styles.mainButton}
            disabled={clauseAction === clauseActions.dismiss || loadInsertion}
            onClick={() => {
              //think should set current clause and current linked clause or get it from index
              //go to chat pop
              setShowPop(true);
              setSuggestAlt(true);
              dispatch(setchatSuggestMessages([]));
              posthog.capture(
                PosthogEventsNames.ReviewTabClausesStepSuggestedAlternativeClauses,
                {
                  distinct_id: user_id, // Use a unique identifier for the user/session
                  time: new Date().toISOString(),
                  session_id: sessionId,
                  stepSessionId: stepSessionId,
                  tab_name: 'review',
                  source: 'word',
                  review_step: 'clauses',
                  clause_action: 'suggest_alternative',
                  documentId: documentId,
                  category: reviewCategory,
                  clause_text: currentSuggestion.original_text,
                  suggested_text: currentSuggestion.suggested_text,
                  clause_type: 'original',
                }
              );
            }}
          >
            Suggest Alternative
          </button>
        )}
        {![clauseActions.insert, clauseActions.keep].includes(clauseAction) && (
          <button
            className={styles.mainButton}
            disabled={
              clauseAction === clauseActions.dismiss ||
              loadInsertion ||
              ((!newSuggest || newSuggest == '') &&
                (currentSuggestion.suggested_text == '' ||
                  !currentSuggestion.suggested_text))
            }
            onClick={async () => {
              setisEditableClause(false);
              let suggest = { ...currentSuggestion };
              if (reviewCategory === ReviewCategory.Missing) {
                await insertTextAfterClause(
                  currentSuggestion.paragraph_before_missing_clause,
                  newSuggest || currentSuggestion.suggested_text
                );
              } else {
                if (newSuggest) {
                  suggest.suggested_text = newSuggest;
                }
                //apply changes in document
                dispatch(applySuggestion(suggest));
              }
              setClauseAction(clauseActions.insert);
              setCurrentSuggestion({
                ...currentSuggestion,
                inserted_before: true,
                current_text: suggest.suggested_text,
              });
              let new_suggestions = suggestions.map(s => ({ ...s }));
              let current_suggest_index = new_suggestions.findIndex(
                s => s.suggestion_id === currentSuggestion.suggestion_id
              );
              new_suggestions[current_suggest_index].current_text =
                suggest.suggested_text;
              new_suggestions[current_suggest_index].inserted_before = true;
              dispatch(setAllSuggestions(new_suggestions));
              //update the suggestion so new inserted text is the original_text now
              posthog.capture(
                PosthogEventsNames.ReviewTabClausesStepInsertedClauses,
                {
                  distinct_id: user_id, // Use a unique identifier for the user/session
                  time: new Date().toISOString(),
                  session_id: sessionId,
                  stepSessionId: stepSessionId,
                  tab_name: 'review',
                  source: 'word',
                  review_step: 'clauses',
                  clause_action: 'insert',
                  documentId: documentId,
                  category: reviewCategory,
                  clause_text: currentSuggestion.original_text,
                  suggested_text: currentSuggestion.suggested_text,
                  clause_type: 'original',
                  suggest_alternative_used: newSuggest ? true : false,
                  suggest_alternative_text: newSuggest,
                }
              );
            }}
          >
            {reviewCategory === ReviewCategory.Missing ? (
              loadInsertion ? (
                <Spinner size="extra-tiny" />
              ) : (
                'Insert in Document'
              )
            ) : (
              'Insert in Document'
            )}
          </button>
        )}
      </div>
      {/* ------------------------------------------------------------------------------------------------------------- */}
      <>
        {currentSuggestion.related_updates.map((linkClause, linkIndex) => {
          return (
            <>
              <div className={styles.commentaryHeading}>
                Suggested Language for Linked Clause {linkClause.clause_number}
              </div>
              <div className={styles.editCnt}>
                {!linkClauseAction?.[linkIndex] && (
                  <div
                    className={styles.editIcon}
                    onClick={() => {
                      setisEditableClauseLinks(prev => {
                        let prev_state = prev[linkIndex] ?? false;
                        return { ...prev, [linkIndex]: !prev_state };
                      });
                    }}
                  >
                    {isEditableClauseLinks[linkIndex] ? (
                      <Dismiss16Regular />
                    ) : (
                      <Edit16Regular />
                    )}
                  </div>
                )}
                {isEditableClauseLinks[linkIndex] ? (
                  <TextField
                    className={styles.commentary}
                    value={
                      linkNewSuggest?.[linkIndex] || linkClause.suggested_text
                    }
                    disabled={linkClauseAction?.[linkIndex]}
                    multiline
                    autoAdjustHeight
                    onClick={() => {
                      //console.log(currentSuggestion.related_updates[linkIndex]);
                      dispatch(
                        scrollToSuggestion(
                          currentSuggestion.related_updates[linkIndex]
                        )
                      );
                    }}
                    onChange={(_, new_value) => {
                      setLinkNewSuggest(prev => {
                        return {
                          ...prev,
                          [linkIndex]: new_value,
                        };
                      });
                    }}
                    styles={{
                      fieldGroup: { border: 'none' },
                      field: {
                        fontSize: '0.687rem',
                        color: '#545454',
                        resize: 'none',
                        border: 'none',
                      },
                      root: { border: 'none', resize: 'none' },
                    }}
                  />
                ) : (
                  <div
                    className={styles.commentary}
                    dangerouslySetInnerHTML={{
                      __html: currentSuggestionPreviewLink[linkIndex] || '',
                    }}
                    onClick={() => {
                      dispatch(
                        scrollToSuggestion(
                          currentSuggestion.related_updates[linkIndex]
                        )
                      );
                    }}
                    style={
                      reviewCategory == ReviewCategory.Missing
                        ? { minHeight: '160px' }
                        : {}
                    }
                  ></div>
                )}
              </div>

              <div className={styles.btnCnt}>
                <button
                  className={
                    linkClauseAction?.[linkIndex] &&
                    [clauseActions.keep, clauseActions.insert].includes(
                      linkClauseAction[linkIndex]
                    )
                      ? styles.rightButton
                      : styles.mainButton
                  }
                  disabled={
                    linkClauseAction?.[linkIndex] === clauseActions.dismiss
                  }
                  onClick={() => {
                    if (linkClauseAction?.[linkIndex]) {
                      setLinkClauseAction(prev => {
                        return {
                          ...prev,
                          [linkIndex]: undefined,
                        };
                      });
                    }
                    if (!linkClauseAction?.[linkIndex]) {
                      setisEditableClauseLinks(prev => {
                        return { ...prev, [linkIndex]: false };
                      });
                      setLinkClauseAction(prev => {
                        return {
                          ...prev,
                          [linkIndex]: clauseActions.keep,
                        };
                      });
                      posthog.capture(
                        PosthogEventsNames.ReviewTabClausesStepKeepExistingClauses,
                        {
                          distinct_id: user_id, // Use a unique identifier for the user/session
                          time: new Date().toISOString(),
                          session_id: sessionId,
                          stepSessionId: stepSessionId,
                          tab_name: 'review',
                          source: 'word',
                          review_step: 'clauses',
                          clause_action: 'keep',
                          documentId: documentId,
                          category: reviewCategory,
                          clause_text: currentSuggestion.original_text,
                          suggested_text: currentSuggestion.suggested_text,
                          clause_type: 'linked',
                        }
                      );
                    }
                  }}
                >
                  {linkClauseAction?.[linkIndex] ? 'Edit' : 'Keep Existing'}
                </button>
                {![clauseActions.insert, clauseActions.keep].includes(
                  linkClauseAction?.[linkIndex]
                ) && (
                  <button
                    className={styles.mainButton}
                    disabled={linkClauseAction?.[linkIndex]}
                    onClick={() => {
                      //think should set current clause and current linked clause or get it from index
                      //go to chat pop
                      setShowPop(true);
                      setSuggestAlt2(linkIndex);
                      setSuggestAlt(false);
                      dispatch(setchatSuggestMessages([]));
                      posthog.capture(
                        PosthogEventsNames.ReviewTabClausesStepSuggestedAlternativeClauses,
                        {
                          distinct_id: user_id, // Use a unique identifier for the user/session
                          time: new Date().toISOString(),
                          session_id: sessionId,
                          stepSessionId: stepSessionId,
                          tab_name: 'review',
                          source: 'word',
                          review_step: 'clauses',
                          clause_action: 'suggest_alternative',
                          documentId: documentId,
                          category: reviewCategory,
                          clause_text: currentSuggestion.original_text,
                          suggested_text: currentSuggestion.suggested_text,
                          clause_type: 'linked',
                        }
                      );
                    }}
                  >
                    Suggest Alternative
                  </button>
                )}
                {![clauseActions.insert, clauseActions.keep].includes(
                  linkClauseAction?.[linkIndex]
                ) && (
                  <button
                    className={styles.mainButton}
                    disabled={
                      linkClauseAction?.[linkIndex] == clauseActions.dismiss
                    }
                    onClick={() => {
                      setisEditableClauseLinks(prev => {
                        return { ...prev, [linkIndex]: false };
                      });
                      setLinkClauseAction(prev => {
                        return {
                          ...prev,
                          [linkIndex]: clauseActions.insert,
                        };
                      });
                      let suggest: RelatedUpdate = {
                        ...(currentSuggestion.related_updates[
                          linkIndex
                        ] as any),
                      };
                      if (linkNewSuggest[linkIndex]) {
                        suggest.suggested_text = linkNewSuggest[linkIndex];
                      }
                      (suggest as DocumentSuggestion).inserted_before = true;
                      suggest.current_text = suggest.suggested_text;
                      dispatch(applySuggestion(suggest));
                      //update current suggestion and suggestions state
                      //---------------------------------------------------------------------
                      setCurrentSuggestion(prev => {
                        let new_current_Suggest = { ...prev };
                        prev.related_updates[linkIndex] = suggest;
                        return new_current_Suggest;
                      });
                      let new_suggestions = [...suggestions];
                      let current_index = new_suggestions.findIndex(
                        s => s.suggestion_id === currentSuggestion.suggestion_id
                      );
                      new_suggestions[current_index].related_updates[
                        linkIndex
                      ] = suggest;
                      dispatch(setAllSuggestions(new_suggestions));
                      //------------------------------------------------------------------
                      //-------------------------------------------------------------------
                      posthog.capture(
                        PosthogEventsNames.ReviewTabClausesStepInsertedClauses,
                        {
                          distinct_id: user_id, // Use a unique identifier for the user/session
                          time: new Date().toISOString(),
                          session_id: sessionId,
                          stepSessionId: stepSessionId,
                          tab_name: 'review',
                          source: 'word',
                          review_step: 'clauses',
                          clause_action: 'insert',
                          documentId: documentId,
                          category: reviewCategory,
                          clause_text: currentSuggestion.original_text,
                          suggested_text: currentSuggestion.suggested_text,
                          clause_type: 'linked',
                          suggest_alternative_used: linkNewSuggest[linkIndex]
                            ? true
                            : false,
                          suggest_alternative_text: linkNewSuggest[linkIndex],
                        }
                      );
                    }}
                  >
                    Insert in Document
                  </button>
                )}
              </div>
            </>
          );
        })}
        {/* -------------------------------------------------------------------------- */}
        {currentSuggestion.legal_research &&
          currentSuggestion.legal_research.summary &&
          currentSuggestion.legal_research.sources &&
          currentSuggestion.legal_research.sources.length > 0 && (
            <>
              <div className={styles.commentaryHeading}>Legal Research</div>
              <div
                className={
                  clauseAction === clauseActions.dismiss
                    ? `${styles.commentary} ${styles.disabledText}`
                    : styles.commentary
                }
              >
                <div> {currentSuggestion.legal_research?.summary}</div>
                <div>
                  {' '}
                  {currentSuggestion.legal_research?.sources.map(s => {
                    return (
                      <div key={s.link}>
                        <a href={s.link} target="_blank" key={s.link}>
                          {s.title}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
      </>
      <div
        className={styles.btnCnt}
        style={{ marginTop: 'auto', paddingTop: '10px' }}
      >
        <button
          className={styles.acceptBtn}
          disabled={clauseAction === clauseActions.dismiss}
          onClick={async () => {
            //insert all main clauses this handling for next clauses
            let new_suggestions = suggestions.map(s => ({ ...s }));
            let current_suggest_index = new_suggestions.findIndex(
              s => s.suggestion_id === currentSuggestion.suggestion_id
            );
            let suggest = { ...currentSuggestion };
            if (newSuggest) {
              suggest.suggested_text = newSuggest;
            }

            if (!clauseAction) {
              if (reviewCategory === ReviewCategory.Missing) {
                await insertTextAfterClause(
                  currentSuggestion.paragraph_before_missing_clause,
                  newSuggest || currentSuggestion.suggested_text
                );
              } else {
                dispatch(applySuggestion(suggest));
              }
              setSavedActions(prev => {
                return {
                  ...prev,
                  [currentSuggestionIndex + reviewCategory]:
                    clauseActions.insert,
                };
              });
              setCurrentSuggestion({
                ...currentSuggestion,
                inserted_before: true,
                current_text: suggest.suggested_text,
              });
              new_suggestions[current_suggest_index].current_text =
                suggest.suggested_text;
              new_suggestions[current_suggest_index].inserted_before = true;
            }
            console.log(new_suggestions[current_suggest_index]);

            for (let linkIndex in currentSuggestion.related_updates) {
              if (!linkClauseAction[linkIndex]) {
                setSavedLinkedActions(prev => {
                  return {
                    ...prev,
                    [currentSuggestionIndex + reviewCategory + linkIndex]:
                      clauseActions.insert,
                  };
                });
                let insertion_clause = currentSuggestion.related_updates[
                  linkIndex
                ] as DocumentSuggestion;
                insertion_clause.suggested_text =
                  linkNewSuggest[linkIndex] ?? insertion_clause.suggested_text;
                dispatch(applySuggestion(insertion_clause));
                new_suggestions[current_suggest_index].related_updates[
                  linkIndex
                ].inserted_before = true;
                new_suggestions[current_suggest_index].related_updates[
                  linkIndex
                ].current_text = insertion_clause.suggested_text;
              }
            }
            console.log(new_suggestions);
            dispatch(setAllSuggestions(new_suggestions));

            if (currentSuggestionIndex < filteredSuggestions.length - 1) {
              navigateToNextSuggestion();
            } else {
              setStepNo(ReviewSteps.review);
            }
            cleanFlags();
            posthog.capture(
              PosthogEventsNames.ReviewTabClausesStepAcceptAllClauses,
              {
                distinct_id: user_id, // Use a unique identifier for the user/session
                time: new Date().toISOString(),
                session_id: sessionId,
                stepSessionId: stepSessionId,
                tab_name: 'review',
                source: 'word',
                review_step: 'clauses',
                clause_action: 'accept',
                documentId: documentId,
                category: reviewCategory,
              }
            );
          }}
        >
          Accept
        </button>
        <button
          className={styles.nextBtn}
          onClick={() => {
            //save clause action before leave
            let allKeys = Object.keys(currentSuggestion.related_updates);
            let linkClauseActionsValues = [];
            for (let key of allKeys) {
              let value = linkClauseAction[key] ?? undefined;
              linkClauseActionsValues.push(value);
            }
            let linkClauseActionsKeys = Object.keys(linkClauseAction);
            if (
              !clauseAction ||
              linkClauseActionsValues.includes(
                undefined
                //  reviewCategory !== ReviewCategory.Missing)
              )
            ) {
              setShowConfirmSave(true);
            } else {
              setSavedActions(prev => {
                return {
                  ...prev,
                  [currentSuggestionIndex + reviewCategory]: clauseAction,
                };
              });
              setSavedLinkedActions(prev => {
                let result = { ...prev };
                for (let linkIndex of linkClauseActionsKeys) {
                  result[currentSuggestionIndex + reviewCategory + linkIndex] =
                    linkClauseAction?.[linkIndex];
                }
                return result;
              });
              navigateToNextSuggestion();
              if (currentSuggestionIndex == filteredSuggestions.length - 1) {
                //finish case all category clauses
                setStepNo(ReviewSteps.review);
              }
              cleanFlags();
            }
          }}
        >
          {currentSuggestionIndex < filteredSuggestions.length - 1
            ? 'Next'
            : 'Continue'}
        </button>
      </div>
      {/* --------------------------------------------------------------------------------------------- */}
      {showConfirmSave &&
        ConfirmMessage(
          styles,
          () => {
            //on discard save action as it was dismissed
            setSavedActions(prev => {
              return {
                ...prev,
                [currentSuggestionIndex + reviewCategory]: undefined,
              };
            });
            setSavedLinkedActions(prev => {
              let result = { ...prev };
              for (let linkIndex in linkClauseAction) {
                if (prev[linkIndex]) {
                  result[currentSuggestionIndex + reviewCategory + linkIndex] =
                    linkClauseAction[linkIndex];
                }
              }
              return result;
            });
            cleanFlags();
            setShowConfirmSave(false);
            //go to next clause if no next go to review categories list
            if (currentSuggestionIndex < filteredSuggestions.length - 1) {
              navigateToNextSuggestion();
            } else {
              setStepNo(ReviewSteps.review);
            }
          },
          async () => {
            // on click Insert
            //save action was insert
            let new_suggestions = suggestions.map(s => ({ ...s }));
            let current_suggest_index = new_suggestions.findIndex(
              s => s.suggestion_id === currentSuggestion.suggestion_id
            );
            if (!clauseAction) {
              let suggest = { ...currentSuggestion };
              if (newSuggest) {
                suggest.suggested_text = newSuggest;
              }

              if (reviewCategory === ReviewCategory.Missing) {
                await insertTextAfterClause(
                  currentSuggestion.paragraph_before_missing_clause,
                  newSuggest || currentSuggestion.suggested_text
                );
              } else {
                dispatch(applySuggestion(suggest));
              }
              setSavedActions(prev => {
                return {
                  ...prev,
                  [currentSuggestionIndex + reviewCategory]:
                    clauseActions.insert,
                };
              });
              new_suggestions[current_suggest_index].current_text =
                suggest.suggested_text;
              new_suggestions[current_suggest_index].inserted_before = true;
              setCurrentSuggestion({
                ...currentSuggestion,
                inserted_before: true,
                current_text: suggest.suggested_text,
              });
            } else {
              setSavedActions(prev => {
                return {
                  ...prev,
                  [currentSuggestionIndex + reviewCategory]:
                    clauseActions.insert,
                };
              });
            }

            //make insertion for any link clause have no action then save action as insert
            setSavedLinkedActions(prev => {
              let result = { ...prev };
              let allKeys = Object.keys(currentSuggestion.related_updates);
              for (let linkIndex of allKeys) {
                if (!linkClauseAction[linkIndex]) {
                  result[currentSuggestionIndex + reviewCategory + linkIndex] =
                    linkClauseAction[linkIndex];
                } else {
                  result[currentSuggestionIndex + reviewCategory + linkIndex] =
                    clauseActions.insert;
                  let suggest = {
                    ...currentSuggestion.related_updates[linkIndex],
                  };
                  if (linkNewSuggest[linkIndex]) {
                    suggest.suggested_text = linkNewSuggest[linkIndex];
                  }
                  dispatch(applySuggestion(suggest));
                  new_suggestions[current_suggest_index].related_updates[
                    linkIndex
                  ].inserted_before = true;
                  new_suggestions[current_suggest_index].related_updates[
                    linkIndex
                  ].current_text = suggest.suggested_text;
                }
              }
              return result;
            });
            //remove message
            setShowConfirmSave(false);
            cleanFlags();
            if (currentSuggestionIndex < filteredSuggestions.length - 1) {
              navigateToNextSuggestion();
            } else {
              setStepNo(ReviewSteps.review);
            }
          }
        )}
    </div>
  );
};
