import * as React from 'react';
import { useState } from 'react';
import styles from './ReviewProgress.module.css';
import { ProgressBar } from '@fluentui/react-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  ThumbDislike16Regular,
  ThumbLike16Regular,
} from '@fluentui/react-icons';
import { sendFeedback } from '../../../../store/socketSlice';
import { RequestEnum } from '../../../../models/base';
import { UserFeedbackRequest } from '../../../../models/feedback';
import { AppDispatch, RootState } from '../../../../store/store';
import { DocumentSuggestion } from '../../../../models/review';
import { FeedbackModal } from 'qanoonisharedpackage';
import { usePostHog } from 'posthog-js/react';
import { PosthogEventsNames } from './const';

export const ReviewProgress = ({
  value,
  max,
  sessionId,
  reviewCategory,
}: {
  value: number;
  max: number;
  reviewCategory: string;
  sessionId: string;
}) => {
  const progress = (value / max) * 100;
  const [isLikeModalOpen, setIsLikeModalOpen] = useState(false);
  const [isDislikeModalOpen, setIsDislikeModalOpen] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState<string>('');
  const dispatch: AppDispatch = useDispatch();
  const posthog = usePostHog();
  const suggestion: DocumentSuggestion = useSelector(
    (state: RootState) => state.office.suggestions[0]
  );
  const user_id = useSelector((state: RootState) => state.socket.user_id);
  const documentId = useSelector((state: RootState) => state.office.documentId);
  const suggestion_id = suggestion.suggestion_id;
  const trace_id = suggestion.trace_id;

  const sendFeedbackGet = (feedbackType: 'thumbs_up' | 'thumbs_down') => {
    const request: UserFeedbackRequest = {
      action: RequestEnum.UserFeedbackRequest,
      feature_type: 'Document_Review_Suggestion',
      feature_ref_id: suggestion_id,
      feedback: feedbackType,
      trace_id: trace_id,
      note: feedbackContent,
    };
    posthog.capture(PosthogEventsNames.ReviewTabClausesStepFeedbackSubmitted, {
      distinct_id: user_id, // Use a unique identifier for the user/session
      time: new Date().toISOString(),
      session_id: sessionId,
      tab_name: 'review',
      source: 'word',
      documentId: documentId,
      reviewCategory: reviewCategory,
      feedbackType: feedbackType,
      feedbackContent: feedbackContent,
    });
    dispatch(sendFeedback(request));
    setIsLikeModalOpen(false);
    setIsDislikeModalOpen(false);
    setFeedbackContent('');
  };

  return (
    <div className={styles.container}>
      <div className={styles.progressContainer}>
        <ProgressBar
          shape="rounded"
          className={styles.progressBar}
          thickness="large"
          value={value}
          max={max || 1} // Ensure max is never zero
        />
        <div className={styles.percentage}>
          {progress ? `${progress.toFixed(0)}%` : '0%'}
        </div>
        <div className={styles.likeCnt}>
          <ThumbLike16Regular
            onClick={() => setIsLikeModalOpen(true)}
            style={{ cursor: 'pointer' }}
          />
          <ThumbDislike16Regular
            onClick={() => setIsDislikeModalOpen(true)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
      <div className={styles.progressHeader}>Correction progress</div>
      <FeedbackModal
        isDislikeModalOpen={isDislikeModalOpen}
        isLikeModalOpen={isLikeModalOpen}
        setFeedbackContent={setFeedbackContent}
        setIsDislikeModalOpen={setIsDislikeModalOpen}
        setIsLikeModalOpen={setIsLikeModalOpen}
        sendFeedbackGet={sendFeedbackGet}
        feedbackContent={feedbackContent}
      />
    </div>
  );
};
