import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  DocumentSessionStarted,
  DocumentSuggestion,
  SectionSummaryResponseChunk,
} from '../models/review';
import {
  CreateTemplateResponse,
  DraftDocumentResponse,
  DraftDocumentResponseChunk,
  FindAndIterateSubsetQuestionsResponse,
  isCreateTemplateResponse,
  isDeleteTemplateResponse,
  isDraftDocumentResponse,
  isDraftDocumentResponseChunk,
  isFindAndIterateSubsetQuestionsResponse,
  isListContractsResponse,
  isListTemplatesResponse,
  isSectionSummaryResponseChunk,
  isStopDraftGenerationResponse,
  ListContractsResponse,
  ListTemplatesResponse,
  QuestionSubset,
  StopDraftGenerationResponse,
} from '../models/draft';
import {
  isChatEmailResponse,
  isChatEmailResponseChunk,
  isReviewDocumentChatResponse,
  isReviewDocumentChatResponseChunk,
  isReviewDocumentSuggestAlternativeChatResponse,
} from '../models/chat';
import { isAnnotationTask } from '../models/annotation';
import { addAnnotation } from './annotationSlice';
import {
  addSuggestion,
  applyGlobalChanges,
  setIsAnalyzing,
  updateDocumentSectionSummary,
  setAttachmentSuccess,
  setDocumentId,
  setCurrentTab,
  setActionsTab, // Add this
} from './officeSlice';
import {
  displayDraftChunk,
  displayDraftResponse,
  handleCreateTemplateResponse,
  handleDeleteTemplateResponse,
  sendTemplateListRequest,
  setContracts,
  setIsLoadingListTemplate,
  setTemplateDocuments,
  setIsLoadingListContract,
  setMandatoryQuestionsSubset,
  setIsLoadingListMandatoryQuestionsSubset,
  setIsGeneratingDraft,
  setDraftResponseChunk,
  setIsDocumentSessionsStarted,
  setStopDraftGenerationResponse,
  setBase64Docx,
} from './draftSlice';
import { tokenHelper } from '../helper/tokenHelper'; // Import the TokenHelper
import {
  GetUserActivityResponse,
  GetUserProcessResponse,
  isGetFeatureFlagResponse,
  isGetUserActivityResonse,
  isGetUserOfflineProcessResponse,
  isNoDraftsResponse,
  isNoReviewsResponse,
  isSearchUserActivityResonse,
} from '../models/userActivity';
import {
  setHasMoreDrafts,
  setHasMoreReviews,
  setLoadingSearch,
  setSearchUserActivities,
  setUserActivity,
  setUserOfflineProcess,
} from './activitySlice';
import {
  handleReviewChatMessageResponse,
  handleReviewChatSuggestAltMessageResponse,
  setReviewProgress,
  setReviewStatus,
} from './reviewSlice';

import {
  setDocument_Review_summary,
  setDocument_summary,
  setLoadReviewSummary,
  setLoadSummary,
} from './documentSlice';
import {
  isDocumentReviewSummaryChatResponse,
  isDocumentReviewSummaryChatResponseChunk,
  isSummarizeDocumentResponse,
  isSummarizeDocumentResponseChunk,
} from '../models/document';
import { RootState } from './store';
import { GlobalChangeWordResponse } from '../models/globalChanges';
import { handleFeatureFlagResponse } from './featureFlagSlice';
import { handleChatMessageResponse } from './qcounselSlice';
import {
  HomeTabs,
  PosthogEventsNames,
  REVIEW_STATUS,
} from '../taskpane/features/review/components/const';
import { initalDraftChunkResponse } from '../taskpane/features/draft/Chat';
import posthog from 'posthog-js';
import { ReviewCategory } from '../taskpane/features/review/components/ReviewList';
import { getMatchingParagraph } from '../helper/textMining';
interface SocketState {
  connection: WebSocket | null;
  isConnected: boolean;
  socketError?: string;
  isLogin?: boolean;
  token?: string;
  accountMgmt?: {
    account_email: string;
    account_response: string;
  };
  is_subscribe: boolean;
  is_subscription_expired: boolean;
  is_trial_expired: boolean;
  user_id?: string;
}

const initialState: SocketState = {
  connection: null,
  isConnected: false,
  socketError: undefined,
  isLogin: true,
  token: undefined,
  accountMgmt: undefined,
  is_subscribe: true,
  is_subscription_expired: false,
  is_trial_expired: false,
  user_id: undefined,
};
let connection: WebSocket | undefined = undefined;

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const SubscriptionSocketMessages = {
  no_subscription: 'no_subscription',
  expired_subscription: 'expired_subscription',
  expired_trial: 'expired_trial',
};
export const initSocket = createAsyncThunk(
  'socket/initSocket',
  async (_, { dispatch, getState }) => {
    console.log('Initializing socket');
    const state = getState() as RootState;
    const token = state.socket.token;

    if (connection) {
      connection = undefined;
    }
    if (!connection) {
      const socketUrlWithToken = `${SOCKET_URL}?token=${token}`;
      console.log('WebSocket URL:', socketUrlWithToken);
      connection = new WebSocket(socketUrlWithToken);
    }

    connection.onopen = () => {
      console.log('WebSocket connection opened.');
      dispatch(setConnected(true));

      // get templates for drafting
      dispatch(sendTemplateListRequest());
    };

    connection.onclose = () => {
      console.log('WebSocket connection closed.');
      dispatch(setConnected(false));
    };

    connection.onmessage = (event: MessageEvent<string>) => {
      try {
        if (event.data == SubscriptionSocketMessages['no_subscription']) {
          dispatch(setIsSubscribe(false));
          dispatch(setIsTrialExpired(false));
          dispatch(setIsSubscriptionExpired(false));
          return;
        }
        if (event.data == SubscriptionSocketMessages['expired_subscription']) {
          dispatch(setIsSubscribe(false));
          dispatch(setIsSubscriptionExpired(true));
          dispatch(setIsTrialExpired(false));

          return;
        }
        if (event.data == SubscriptionSocketMessages['expired_trial']) {
          dispatch(setIsSubscribe(false));
          dispatch(setIsSubscriptionExpired(false));
          dispatch(setIsTrialExpired(true));
          return;
        }

        const response: any = JSON.parse(event.data);
        console.log('message received', { response });
        if (response.status == 'error') {
          dispatch(setError(response.errorMessage));
        }

        //Sigin Response
        if (response.action === 'SignIn') {
          console.log('Sign In required');
          tokenHelper.getAccessToken(accesstoken => {
            dispatch(setTokenState(accesstoken));
          }, true);
          if (state.socket.isLogin) {
            dispatch(setIsLogin(false));
          }
        }

        switch (response.action) {
          case 'GetAccountMgmtResponse':
            console.log('Received isGetAccountMgmtResponse:', response);
            dispatch(setaccountMgmt(response));
            if (response && (response as any).account_response == 'SignOut') {
              console.log('SignOut');
              tokenHelper.getAccessToken(accesstoken => {
                dispatch(setTokenState(accesstoken));
              }, true);
            }
            break;
          case 'ListContractsResponse':
            const listContractsResponse = response as ListContractsResponse;
            console.log(
              'ListContractsResponse',
              listContractsResponse.contracts
            );
            dispatch(setContracts(listContractsResponse.contracts));
            break;
          case 'FindAndIterateSubsetQuestionsResponse':
            const findAndIterateSubsetQuestionsResponse =
              response as FindAndIterateSubsetQuestionsResponse;
            console.log(
              'findAndIterateSubsetQuestionsResponse',
              findAndIterateSubsetQuestionsResponse
            );

            const question: QuestionSubset = {
              current_sequence:
                findAndIterateSubsetQuestionsResponse?.current_sequence,
              question: findAndIterateSubsetQuestionsResponse?.question,
              is_end: findAndIterateSubsetQuestionsResponse?.is_end,
              retained_info:
                findAndIterateSubsetQuestionsResponse?.retained_info,
              type: findAndIterateSubsetQuestionsResponse?.type,
              variable: findAndIterateSubsetQuestionsResponse?.variable,
              subtype: findAndIterateSubsetQuestionsResponse?.subtype,
              response: findAndIterateSubsetQuestionsResponse?.response,
              original_clause:
                findAndIterateSubsetQuestionsResponse?.original_clause,
            };

            console.log('question', question);
            dispatch(setMandatoryQuestionsSubset(question));
            dispatch(setIsLoadingListMandatoryQuestionsSubset(false));
            break;
          case 'OpenWordResponse':
            break;
          case 'DocumentReviewResponse':
            break;
          case 'DocumentSessionStarted':
            // to allow IDE to correctly infer the type of response
            const typedResponse = response as DocumentSessionStarted;
            console.log(
              'DocumentSessionStarted',
              typedResponse.user_document_id
            );
            dispatch(setIsDocumentSessionsStarted(true));
            dispatch(setDocumentId(typedResponse.user_document_id));
            dispatch(setUserId(typedResponse.user_id));
            break;
          case 'SuggestionItem':
            let current_statee = getState() as RootState;
            if (response.category == ReviewCategory.Missing) {
              let text_filtered = response.paragraph_before_missing_clause
                .split('\n')[0]
                .trim()
                .replace(/\s+/g, ' ')
                .split(' ')
                .splice(1)
                .join(' ');
              let matchingParagraph = getMatchingParagraph(
                text_filtered,
                current_statee.office.sanitizedParagraphs
              );
              if (matchingParagraph) {
                let clause = { ...response } as DocumentSuggestion;
                clause.paragraph_before_missing_clause = text_filtered;
                dispatch(addSuggestion(clause));
              }
            } else {
              let matchingParagraph = getMatchingParagraph(
                response.original_text,
                current_statee.office.sanitizedParagraphs
              );
              if (
                matchingParagraph &&
                response.original_text &&
                response.original_text !== ''
              ) {
                dispatch(
                  addSuggestion({
                    ...response,
                  } as DocumentSuggestion)
                );
              }
            }

            if (state.review.reviewStatus !== REVIEW_STATUS.suggest) {
              dispatch(setReviewStatus(REVIEW_STATUS.suggest));
              dispatch(setReviewProgress(90));
            }
            posthog.capture(
              PosthogEventsNames.ReviewTabReviewDocumentSuggestionReceived,
              {
                distinct_id: current_statee.socket.user_id, // Use a unique identifier for the user/session
                time: new Date().toISOString(),
                session_id: response.session_id,
                tab_name: 'review',
                source: 'word',
                documentId: current_statee.office.documentId,
                category: response.category,
                linked_clause_count: response.related_updates
                  ? response.related_updates.length
                  : 0,
                original_text: response.original_text,
                suggested_text: response.suggested_text,
              }
            );

            break;
          case 'DocumentReviewComplete':
            dispatch(setIsAnalyzing(false));
            dispatch(setReviewStatus(REVIEW_STATUS.complete));
            dispatch(setReviewProgress(100));
            let current_state = getState() as RootState;
            let suggestions = current_state.office.suggestions;
            posthog.capture(
              PosthogEventsNames.ReviewTabReviewDocumentResponseReceived,
              {
                distinct_id: current_state.socket.user_id, // Use a unique identifier for the user/session
                time: new Date().toISOString(),
                session_id: (response as any).session_id,
                sending_time: (response as any).sending_time,
                tab_name: 'review',
                source: 'word',
                documentId: current_state.office.documentId,
                no_suggestions: suggestions.length,
                unacceptable_count: suggestions.filter(
                  x => x.category === 'Unacceptable'
                ).length,
                acceptable_count: suggestions.filter(
                  x => x.category === 'Acceptable'
                ).length,
                nonStandard_count: suggestions.filter(
                  x => x.category === 'Non-Standard'
                ).length,
                missing_count: suggestions.filter(x => x.category === 'Missing')
                  .length,
                response_duration_in_second:
                  (new Date().getTime() -
                    new Date((response as any).sending_time).getTime()) /
                  1000,
              }
            );

            break;
          case 'GlobalChangeWordResponse':
            dispatch(applyGlobalChanges(response as GlobalChangeWordResponse));
            break;
          case 'SectionSummaryResponse':
            console.log('SectionSummaryResponse', response);
            break;
          case 'UpdateDocumentAttachmentResponse':
            if (response.status === 'success') {
              dispatch(setAttachmentSuccess(true));
            }
            break;
          default:
            break;
        }

        // DRAFT LOGIC

        if (isDraftDocumentResponse(response)) {
          console.log('Processing DraftDocumentResponse');
          const draftDocumentResponse = response as DraftDocumentResponse;
          console.log('DocumentReviewResponse', draftDocumentResponse);
          dispatch(setIsGeneratingDraft(!draftDocumentResponse.is_complete));

          // Set base64 data first
          console.log('Setting base64Docx');
          dispatch(setBase64Docx(draftDocumentResponse.docx_base64));

          // Then display the response
          console.log('About to dispatch displayDraftResponse');
          dispatch(displayDraftResponse(draftDocumentResponse.document_text));
        } else if (isDraftDocumentResponseChunk(response)) {
          const draftDocumentResponseChunk =
            response as DraftDocumentResponseChunk;
          console.log(
            'DocumentReviewResponseChunk',
            draftDocumentResponseChunk
          );
          dispatch(
            setIsGeneratingDraft(!draftDocumentResponseChunk.is_complete)
          );
          dispatch(setDraftResponseChunk(draftDocumentResponseChunk));
          dispatch(displayDraftChunk(draftDocumentResponseChunk.document_text));
        } else if (isStopDraftGenerationResponse(response)) {
          const stopDraftGenerationResponse =
            response as StopDraftGenerationResponse;
          console.log(
            'StopDraftGenerationResponse',
            stopDraftGenerationResponse
          );
          dispatch(setStopDraftGenerationResponse(stopDraftGenerationResponse));
          dispatch(setDraftResponseChunk(initalDraftChunkResponse));
          dispatch(setIsGeneratingDraft(null));
        } else if (isListTemplatesResponse(response)) {
          const listTemplateResponse = response as ListTemplatesResponse;
          console.log(
            'ListTemplateLibraryResponse',
            listTemplateResponse.documents
          );
          dispatch(setTemplateDocuments(listTemplateResponse.documents));
          dispatch(setIsLoadingListTemplate(false));
        } else if (isCreateTemplateResponse(response)) {
          const createTemplateResponse = response as CreateTemplateResponse;
          console.log('CreateTemplateResponse', createTemplateResponse);
          dispatch(handleCreateTemplateResponse(createTemplateResponse));
        } else if (isSectionSummaryResponseChunk(response)) {
          console.log('SectionSummaryResponseChunk', response);
          dispatch(
            updateDocumentSectionSummary(
              response as SectionSummaryResponseChunk
            )
          );
        } else if (isDeleteTemplateResponse(response)) {
          console.log('DeleteTemplateResponse', response);
          dispatch(handleDeleteTemplateResponse(response));
        } else if (isListContractsResponse(response)) {
          const listContractsResponse = response as ListContractsResponse;
          console.log('ListContractsResponse', listContractsResponse.contracts);
          dispatch(setContracts(listContractsResponse.contracts));
          dispatch(setIsLoadingListContract(false));
        } else if (isFindAndIterateSubsetQuestionsResponse(response)) {
          const findAndIterateSubsetQuestionsResponse =
            response as FindAndIterateSubsetQuestionsResponse;
          console.log(
            'FindAndIterateSubsetQuestionsResponse',
            findAndIterateSubsetQuestionsResponse
          );
          const question: QuestionSubset = {
            current_sequence:
              findAndIterateSubsetQuestionsResponse?.current_sequence,
            question: findAndIterateSubsetQuestionsResponse?.question,
            is_end: findAndIterateSubsetQuestionsResponse?.is_end,
            retained_info: findAndIterateSubsetQuestionsResponse?.retained_info,
            type: findAndIterateSubsetQuestionsResponse?.type,
            subtype: findAndIterateSubsetQuestionsResponse?.subtype,
            variable: findAndIterateSubsetQuestionsResponse?.variable,
            response: findAndIterateSubsetQuestionsResponse?.response,
            original_clause:
              findAndIterateSubsetQuestionsResponse?.original_clause,
          };
          dispatch(setMandatoryQuestionsSubset(question));
          dispatch(setIsLoadingListMandatoryQuestionsSubset(false));
        }

        // CHAT LOGIC
        if (
          isChatEmailResponseChunk(response) ||
          isChatEmailResponse(response)
        ) {
          dispatch(handleChatMessageResponse(response));
        }
        if (
          isReviewDocumentChatResponseChunk(response) ||
          isReviewDocumentChatResponse(response)
        ) {
          dispatch(handleReviewChatMessageResponse(response));
        }
        if (
          // isReviewDocumentSuggestAlternativeChatResponseChunk(response) ||
          isReviewDocumentSuggestAlternativeChatResponse(response)
        ) {
          dispatch(handleReviewChatSuggestAltMessageResponse(response));
        }
        // DEV DEBUG LOGIC
        if (isAnnotationTask(response)) {
          console.log('AnnotationTask', response);
          dispatch(addAnnotation(response));
        }
        if (isGetUserActivityResonse(response)) {
          const userActivityResponse = response as GetUserActivityResponse;
          console.log('get user Activities response', response);
          dispatch(setUserActivity(userActivityResponse.activity));
          dispatch(setHasMoreReviews(userActivityResponse.has_more_reviews));
          dispatch(setHasMoreDrafts(userActivityResponse.has_more_drafts));
        }
        if (isNoReviewsResponse(response)) {
          dispatch(setHasMoreReviews(false));
          dispatch(setCurrentTab(HomeTabs.Actions));
          dispatch(setActionsTab(undefined));
          dispatch(setIsAnalyzing(false));
        }
        if (isNoDraftsResponse(response)) {
          dispatch(setHasMoreDrafts(false));
          dispatch(setCurrentTab(HomeTabs.Actions));
          dispatch(setActionsTab(undefined));
        }
        if (isGetUserOfflineProcessResponse(response)) {
          const userOfflineResponse = response as GetUserProcessResponse;
          console.log('get user offline process response', response);
          dispatch(setUserOfflineProcess(userOfflineResponse.process));
        }
        if (isSummarizeDocumentResponseChunk(response)) {
          dispatch(setDocument_summary(response.summary));
          dispatch(setLoadSummary('streaming'));
        }
        if (isSummarizeDocumentResponse(response)) {
          dispatch(setDocument_summary(response.summary));
          dispatch(setLoadSummary(false));
        }
        if (isDocumentReviewSummaryChatResponseChunk(response)) {
          dispatch(
            setDocument_Review_summary(response.document_review_summary)
          );
          dispatch(setLoadReviewSummary('streaming'));
        }
        if (isDocumentReviewSummaryChatResponse(response)) {
          dispatch(
            setDocument_Review_summary(response.document_review_summary)
          );
          dispatch(setLoadReviewSummary(false));
        }
        if (isGetFeatureFlagResponse(response)) {
          dispatch(handleFeatureFlagResponse(response));
        }
        if (isSearchUserActivityResonse(response)) {
          const searchUserActivityResponse =
            response as GetUserActivityResponse;
          console.log('get user search Activities response', response);
          dispatch(
            setSearchUserActivities(searchUserActivityResponse.activity)
          );
          if (searchUserActivityResponse.activity.length == 0) {
            dispatch(setLoadingSearch('no-result'));
          } else {
            dispatch(setLoadingSearch(false));
          }
        }
      } catch (error) {
        console.log('error in socket slice', error);
      }
    };
  }
);

export const sendMessage = createAsyncThunk<void, any>(
  'socket/sendMessage',
  async (message: any, { dispatch }) => {
    try {
      console.log('sending message', message, { connection });
      if (connection && connection.readyState === WebSocket.OPEN) {
        console.log('Socket is open. Sending message.');
        dispatch(setError(undefined));
        connection.send(JSON.stringify(message));
      }
    } catch (error) {
      console.log('Couldnt send over the message');
    }
  }
);

export const sendFeedback = createAsyncThunk<void, any>(
  'socket/sendFeedback',
  async (message: any, { dispatch }) => {
    console.log('sending feedback', message, { connection });
    if (connection && connection.readyState === WebSocket.OPEN) {
      console.log('Socket is open. Sending feedback.');
      dispatch(setError(undefined));
      connection.send(JSON.stringify(message));
    }
  }
);
export const GetAccountMgmt = createAsyncThunk<void, any>(
  'socket/GetAccountMgmt',
  async (message: any, {}) => {
    console.log('sending feedback', message, { connection });
    if (connection && connection.readyState === WebSocket.OPEN) {
      console.log('Sending to GetAccountMgmtRequest:', message);
      connection.send(JSON.stringify(message));
    }
  }
);

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    setConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setIsSubscribe: (state, action: PayloadAction<boolean>) => {
      state.is_subscribe = action.payload;
    },
    setIsSubscriptionExpired: (state, action: PayloadAction<boolean>) => {
      state.is_subscription_expired = action.payload;
    },
    setIsTrialExpired: (state, action: PayloadAction<boolean>) => {
      state.is_trial_expired = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      console.log('setting error', action.payload);
      state.socketError = action.payload;
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setTokenState: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setaccountMgmt: (state, action: PayloadAction<any>) => {
      state.accountMgmt = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.user_id = action.payload;
    },
  },
});

export const {
  setConnected,
  setError,
  setIsLogin,
  setTokenState,
  setaccountMgmt,
  setIsSubscribe,
  setIsSubscriptionExpired,
  setIsTrialExpired,
  setUserId,
} = socketSlice.actions;

export default socketSlice.reducer;
