import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sendMessage } from './socketSlice';
import { RequestEnum } from '../models/base';

import {
  GetFeatureFlagRequest,
  GetFeatureFlagResponse,
} from '../models/featureFlag';

interface DocumentState {
  displayDraftFlagValue?: boolean;
  displayGlobalChangesFlagValue?: boolean;
  subscriptionRequiredFlagValue?: boolean;
}

const initialState: DocumentState = {
  displayDraftFlagValue: undefined,
  displayGlobalChangesFlagValue: undefined,
  subscriptionRequiredFlagValue: undefined,
};
//-------------------------------------------------------------
export const getSubscriptionRequiredFlag = createAsyncThunk(
  '/featureFlag/getDisplayDraftFlag',
  async ({}: {}, { dispatch }) => {
    const requestTosend: GetFeatureFlagRequest = {
      action: RequestEnum.GetFeatureFlagRequest,
      feature_name: 'subscription_required',
    };
    //save first version of document content
    console.log('get subscription_required');
    dispatch(sendMessage(requestTosend));
  }
);
//-------------------------------------------------------------
export const getDisplayDraftFlag = createAsyncThunk(
  '/featureFlag/getDisplayDraftFlag',
  async ({}: {}, { dispatch }) => {
    const requestTosend: GetFeatureFlagRequest = {
      action: RequestEnum.GetFeatureFlagRequest,
      feature_name: 'display_draft_feature',
    };
    //save first version of document content
    console.log('get display_draft_feature');
    dispatch(sendMessage(requestTosend));
  }
);
//-------------------------------------------------------------
export const getDisplayGlobalChangesFlag = createAsyncThunk(
  '/featureFlag/getDisplayGlobalChangesFlag',
  async ({}: {}, { dispatch }) => {
    const requestTosend: GetFeatureFlagRequest = {
      action: RequestEnum.GetFeatureFlagRequest,
      feature_name: 'display_global_changes_feature',
    };
    //save first version of document content
    dispatch(sendMessage(requestTosend));
  }
);
//---------------------------------------------------------------------------
export const handleFeatureFlagResponse = createAsyncThunk(
  'featureFlag/handleFeatureFlagResponse',
  async (response: GetFeatureFlagResponse, { dispatch }) => {
    if (response.feature_name == 'display_draft_feature') {
      let feature_value = response.feature_value === 'false' ? false : true;

      dispatch(setdisplayDraftFlagValue(feature_value));
    }
    if (response.feature_name == 'display_global_changes_feature') {
      let feature_value = response.feature_value === 'false' ? false : true;

      dispatch(setdisplayGlobalChangesFlagValue(feature_value));
    }
    if (response.feature_name == 'subscription_required') {
      let feature_value = response.feature_value === 'true' ? true : false;

      dispatch(setdisplayGlobalChangesFlagValue(feature_value));
    }
  }
);
//---------------------------------------------------------------------------
export const documentSlice = createSlice({
  name: 'featureFlag',
  initialState,
  reducers: {
    setdisplayDraftFlagValue: (state, action: PayloadAction<boolean>) => {
      state.displayDraftFlagValue = action.payload;
    },
    setSubscriptionRequiredFlagValue: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.subscriptionRequiredFlagValue = action.payload;
    },
    setdisplayGlobalChangesFlagValue: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.displayGlobalChangesFlagValue = action.payload;
    },
  },
});
//----------------------------------------------------------------------------------------
export const {
  setdisplayDraftFlagValue,
  setdisplayGlobalChangesFlagValue,
  setSubscriptionRequiredFlagValue,
} = documentSlice.actions;

export default documentSlice.reducer;
