import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Summary.module.css';
import {
  Copy16Regular,
  ThumbDislike16Regular,
  ThumbLike16Regular,
  Checkmark16Regular,
} from '@fluentui/react-icons';
import { MarkdownRenderer } from '../../shared/misc/MarkdownRenderer';
import { AppDispatch, RootState } from '../../../store/store';
import { Image, Spinner } from '@fluentui/react-components';
import { summarizeDocument } from '../../../store/documentSlice';
import { sendFeedback } from '../../../store/socketSlice';
import { RequestEnum } from '../../../models/base';
import { UserFeedbackRequest } from '../../../models/feedback';
import { FeedbackModal } from 'qanoonisharedpackage';

const DocumentSummary = () => {
  const dispatch = useDispatch<AppDispatch>();
  const summary = useSelector(
    (state: RootState) => state.document.document_summary
  );
  const isEmptyDocument = useSelector(
    (state: RootState) => state.office.isEmptyDocument
  );
  const loadSummary = useSelector(
    (state: RootState) => state.document.load_sumaary
  );
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );

  const [isLikeModalOpen, setIsLikeModalOpen] = useState(false);
  const [isDislikeModalOpen, setIsDislikeModalOpen] = useState(false);
  const [feedbackContent, setFeedbackContent] = useState<string>('');
  const [isToastVisible, setIsToasVisible] = useState(false);
  const showNotification = () => {
    const id = new Date().getTime(); // Unique id based on timestamp
    setIsToasVisible(true);
    // Automatically remove the notification after 3 seconds
    setTimeout(() => {
      setIsToasVisible(false);
    }, 1500);
  };

  useEffect(() => {
    if (
      isEmptyDocument !== undefined &&
      !isEmptyDocument &&
      isConnected &&
      !summary
    ) {
      dispatch(summarizeDocument({}));
    }
  }, [isEmptyDocument, isConnected, summary]);

  if (!summary || loadSummary === true) {
    return <Spinner size="small" style={{ padding: '25px' }} />;
  }

  const sendFeedbackGet = (feedbackType: 'thumbs_up' | 'thumbs_down') => {
    const request: UserFeedbackRequest = {
      action: RequestEnum.UserFeedbackRequest,
      feature_type: 'Document Summary',
      feature_ref_id: null,
      feedback: feedbackType,
      trace_id: null,
      note: feedbackContent,
    };
    dispatch(sendFeedback(request));
    setIsLikeModalOpen(false);
    setIsDislikeModalOpen(false);
    setFeedbackContent('');
  };

  return (
    <div>
      <div className={styles.summaryPage}>
        <MarkdownRenderer markdown={summary} />
        <div className={styles.likeCnt}>
          <button
            title="Regenrate"
            className={styles.summIcon}
            disabled={loadSummary == 'streaming'}
            onClick={() => dispatch(summarizeDocument({}))}
          >
            {loadSummary === 'streaming' ? (
              <Spinner size="extra-tiny" />
            ) : (
              <Image src="/assets/regenerate.svg" />
            )}
          </button>
          <button
            title="Copy text"
            className={styles.summIcon}
            onClick={async () => {
              await navigator.clipboard.writeText(summary);

              showNotification();
            }}
          >
            {isToastVisible ? <Checkmark16Regular /> : <Copy16Regular />}
          </button>
          <button
            title="Like"
            className={styles.summIcon}
            onClick={() => setIsLikeModalOpen(true)}
          >
            <ThumbLike16Regular />
          </button>
          <button
            title="Dislike"
            className={styles.summIcon}
            onClick={() => setIsDislikeModalOpen(true)}
          >
            <ThumbDislike16Regular />
          </button>
        </div>
      </div>

      <FeedbackModal
        isDislikeModalOpen={isDislikeModalOpen}
        isLikeModalOpen={isLikeModalOpen}
        setFeedbackContent={setFeedbackContent}
        setIsDislikeModalOpen={setIsDislikeModalOpen}
        setIsLikeModalOpen={setIsLikeModalOpen}
        sendFeedbackGet={sendFeedbackGet}
        feedbackContent={feedbackContent}
      />
    </div>
  );
};

export default DocumentSummary;
