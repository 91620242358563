import { getMatchingParagraph, getSantitizedParagraphs } from './textMining';

export async function insertTextAfterClause(
  prevClauseText: string,
  newText: string
) {
  await Word.run(async context => {
    // Use getMatchingParagraph to find the previous clause's paragraph (e.g., 5.3)
    let sanitizedParagraphs_updated = await getSantitizedParagraphs(context);
    let matchingParagraph = getMatchingParagraph(
      prevClauseText.trim(),
      sanitizedParagraphs_updated
    );
    let parentParagraph;
    if (matchingParagraph) {
      const wordParagraphs = context.document.body.paragraphs;
      wordParagraphs.load('text');
      await context.sync();
      parentParagraph = wordParagraphs.items[matchingParagraph.index];
      parentParagraph.load('listItem');
      await context.sync();
    }
    if (!matchingParagraph) {
      console.log('no matching paragraph founded');
      var body = context.document.body;
      // Search for the starting sentence of the clause
      var searchResults = body.search(matchingParagraph.text.slice(0, 150), {
        matchCase: false,
        matchWholeWord: false,
      });
      context.load(searchResults, 'items');
      await context.sync();
      if (searchResults.items.length > 0) {
        parentParagraph = searchResults.items[0].paragraphs.getLast();
        parentParagraph.load('listItem');
        await context.sync();
      } else {
        console.log('no results founded');
        return;
      }
    }

    let next;
    let new_paragraph;
    let LoopFlag = true;
    try {
      next = parentParagraph.getNext();
      new_paragraph = parentParagraph.getNext();
      new_paragraph.load('listItem');
      await context.sync();
      if (new_paragraph.listItem.level < parentParagraph.listItem.level) {
        new_paragraph = null;
        LoopFlag = false;
      }
    } catch {
      console.log('no next paragraph');
      new_paragraph = null;
      LoopFlag = false;
    }

    while (LoopFlag) {
      if (new_paragraph.listItem.level <= parentParagraph.listItem.level) {
        break;
      } else {
        new_paragraph = next.getNext();
        if (new_paragraph) {
          new_paragraph.load('listItem');
          await context.sync();
          next = new_paragraph;
        } else {
          console.log('no next paragraph');
          break;
        }
        continue;
      }
    }
    if (new_paragraph) {
      let final_paragraph = (new_paragraph as Word.Paragraph).insertParagraph(
        newText.trim().replace(/\n/g, '\r'),
        Word.InsertLocation.before
      );
      final_paragraph.load('listItem');
      await context.sync();
      final_paragraph.listItem.set({
        level: parentParagraph.listItem.level,
      });
      final_paragraph.select();
      await context.sync();
    } else {
      let final_paragraph = parentParagraph.insertParagraph(
        newText.trim().replace(/\n/g, '\r'),
        Word.InsertLocation.after
      );
      final_paragraph.select();
      await context.sync();
    }
  });
}

export function removeClauseByText(text: string) {
  Word.run(async context => {
    const body = context.document.body;
    const normalizedText = text.trim().replace('\n', ' ');
    var searchResults = body.search(normalizedText.slice(0, 150), {
      matchCase: false,
      matchWholeWord: false,
    });
    context.load(searchResults, 'items');
    await context.sync();
    if (searchResults.items.length > 0) {
      const paragraph = searchResults.items[0].paragraphs.getLast();
      paragraph.delete();
      await context.sync();
    } else {
      let sanitizedParagraphs_updated = await getSantitizedParagraphs(context);
      let matchingParagraph = getMatchingParagraph(
        text.trim(),
        sanitizedParagraphs_updated
      );
      if (matchingParagraph) {
        const wordParagraphs = context.document.body.paragraphs;
        wordParagraphs.load('text');
        await context.sync();
        const paragraph = wordParagraphs.items[matchingParagraph.index];
        paragraph.delete();
        await context.sync();
      } else {
        console.log('no matching paragraphs to delete');
      }
    }
  });
}
// if the recommended insertion is 3.1(j) the text before should be for 3.1(i)
// if it is 3.2 so text before should be for 3.1
// if it is 4. the text before should be text for 3.
// if there is recommendation for 7.3 two times one become 7.3 and the other one become 7.4
