import { BaseRequest, BaseResponse, ResponseEnum } from './base';
import { GetFeatureFlagResponse } from './featureFlag';

export interface UserActivity {
  id?: string;
  activity_type: string;
  conversation_id?: string;
  document_id?: string;
  user_id: string;
  activity_date: string;
  document_name?: string;
  matter_summary?: string;
  document_path?: string;
}

export interface UserOfflineProcess {
  id?: string;
  enabled: boolean;
  in_progress: boolean;
  status?: string;
  matter_processed: number;
  matter_total: number;
  email_processed: number;
  email_total: number;
  newest_online?: string;
  newest_processed?: string;
  oldest_online?: string;
  oldest_processed?: string;
  ingest_total: number;
  oldest_ingested: string;
  user_id: string;
  created_at: string; // or Date if you're working with Date objects
  updated_at: string; // or Date if you're working with Date objects
}
export interface GetUserProcessRequest extends BaseRequest {}

export interface GetUserActivityResponse extends BaseResponse {
  activity: UserActivity[];
  has_more_reviews: boolean;
  has_more_drafts: boolean;
}
export interface GetUserProcessResponse extends BaseResponse {
  process: UserOfflineProcess;
}
export const isGetUserActivityResonse = (
  response: any
): response is GetUserActivityResponse => {
  return response.action === ResponseEnum.GetUserActivityResponse;
};
export const isNoReviewsResponse = (response: any): response is any => {
  return response.action === ResponseEnum.NoReviewsResponse;
};
export const isNoDraftsResponse = (response: any): response is any => {
  return response.action === ResponseEnum.NoDraftsResponse;
};
export const isSearchUserActivityResonse = (
  response: any
): response is GetUserActivityResponse => {
  return response.action === ResponseEnum.SearchUserActivityResponse;
};
export const isGetFeatureFlagResponse = (
  response: any
): response is GetFeatureFlagResponse => {
  return response.action === ResponseEnum.GetFeatureFlagResponse;
};
export const isGetUserOfflineProcessResponse = (
  response: any
): response is GetUserProcessResponse => {
  return response.action === ResponseEnum.GetUserProcessResponse;
};
export interface GetUserActivityRequest {}

export interface SearchUserActivityRequest {
  message: string;
  action: string;
}
