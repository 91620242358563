import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sendMessage } from './socketSlice';
import { RequestEnum } from '../models/base';
import {
  GetUserActivityRequest,
  GetUserProcessRequest,
  SearchUserActivityRequest,
  UserActivity,
  UserOfflineProcess,
} from '../models/userActivity';

interface UserActivityState {
  userActivity: UserActivity[];
  userOfflineProcess: UserOfflineProcess;
  searchUserActivities: UserActivity[];
  loadingSearch: any;
  has_more_reviews: boolean;
  has_more_drafts: boolean;
}

const initialState: UserActivityState = {
  userActivity: undefined,
  userOfflineProcess: undefined,
  searchUserActivities: [],
  loadingSearch: false,
  has_more_reviews: undefined,
  has_more_drafts: undefined,
};
//--------------------------------------------------------------------------------------------------
export const SearchUserActivityFn = createAsyncThunk(
  '/activity/searchUserActivity',
  async ({ message }: { message: string }, { dispatch }) => {
    const messageToSend: SearchUserActivityRequest = {
      action: RequestEnum.SearchUserActivityRequest,
      message: message,
    };

    console.log('search user activity', messageToSend);

    dispatch(sendMessage(messageToSend));
  }
);
//--------------------------------------------------------------------------
export const GetUserActivity = createAsyncThunk(
  '/activity/getUserActivity',
  async ({}: GetUserActivityRequest, { dispatch }) => {
    const message: GetUserActivityRequest = {
      action: RequestEnum.GetUserActivityRequest,
    };

    console.log('get user activity', message);

    dispatch(sendMessage(message));
  }
);
//------------------------------------------------------------------
export const getUserOfflineProcess = createAsyncThunk(
  '/activity/getUserOfflineProcess',
  async ({}: {}, { dispatch }) => {
    const message: GetUserProcessRequest = {
      action: RequestEnum.GetUserProcessRequest,
    };

    console.log('get user offline process', message);

    dispatch(sendMessage(message));
  }
);
//--------------------------------------------------------------------
export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setUserActivity: (state, action: PayloadAction<UserActivity[]>) => {
      state.userActivity = action.payload;
    },
    setUserOfflineProcess: (
      state,
      action: PayloadAction<UserOfflineProcess>
    ) => {
      state.userOfflineProcess = action.payload;
    },
    setSearchUserActivities: (state, action: PayloadAction<UserActivity[]>) => {
      state.searchUserActivities = action.payload;
    },
    setLoadingSearch: (state, action: PayloadAction<any>) => {
      state.loadingSearch = action.payload;
    },
    setHasMoreReviews: (state, action: PayloadAction<boolean>) => {
      state.has_more_reviews = action.payload;
    },
    setHasMoreDrafts: (state, action: PayloadAction<boolean>) => {
      state.has_more_drafts = action.payload;
    },
  },
});

export const {
  setUserActivity,
  setSearchUserActivities,
  setLoadingSearch,
  setUserOfflineProcess,
  setHasMoreDrafts,
  setHasMoreReviews,
} = activitySlice.actions;

export default activitySlice.reducer;
