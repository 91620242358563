import React from 'react';
import styles from '../ReviewTab.module.css';
import { ReviewSteps } from './const';
import { SmallLeftArrowIcon } from '../../../../assets/icons/SmallLeftArrow';
import { ReviewProgress } from './ReviewProgress';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import { setActionsTab } from '../../../../store/officeSlice';

const Header = ({
  setpNo,
  setStepNo,
  value,
  max,
  settypingDelay,
  isAnalyzing,
  sessionId,
  reviewCategory,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      {[ReviewSteps.clauses, ReviewSteps.chat, ReviewSteps.review].includes(
        setpNo
      ) && (
        <div
          className={styles.backIcon}
          style={
            setpNo === ReviewSteps.chat
              ? {
                  marginTop: '0px',
                  marginLeft: '5px',
                  marginRight: '0px',
                  marginBottom: '15px',
                }
              : setpNo === ReviewSteps.review && isAnalyzing
              ? { cursor: 'not-allowed' }
              : undefined
          }
          onClick={() => {
            if (setpNo === ReviewSteps.clauses) {
              setStepNo(ReviewSteps.review);
            }
            if (setpNo === ReviewSteps.review && !isAnalyzing) {
              setStepNo(ReviewSteps.chat);
              settypingDelay(0);
            }
            if (setpNo === ReviewSteps.chat) {
              setStepNo(undefined);
              dispatch(setActionsTab(undefined));
            }
          }}
        >
          <SmallLeftArrowIcon />
        </div>
      )}

      {setpNo == ReviewSteps.clauses && (
        <div className={styles.progressCnt}>
          <ReviewProgress
            value={value}
            max={max}
            sessionId={sessionId}
            reviewCategory={reviewCategory}
          />
        </div>
      )}
    </>
  );
};

export default Header;
