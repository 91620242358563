export const ReviewSteps = {
  initial: 1,
  chat: 2,
  review: 3,
  clauses: 4,
  summary: 5,
};
export const ReviewTabs = {
  review: 1,
  summary: 2,
};

export enum HomeTabs {
  Home = 'Home',
  Review = 'Review',
  Draft = 'Draft',
  Global_changes = 'Global Changes',
  Actions = 'actions',
  Summary = 'summary',
}
export const ActionsTabs = {
  draft: 'draft',
  review: 'review',
  globalChanges: 'global changes',
};

export const REVIEW_STATUS = {
  review: 'Reviewing',
  suggest: 'Suggesting',
  complete: 'Completed',
};
export const PosthogEventsNames = {
  ReviewTabReviewDocumentSuggestionReceived:
    'ReviewTab ReviewDocument Suggestion Received',
  ReviewTabOpened: 'ReviewTab Opened',
  ReviewTabClosed: 'ReviewTab Closed',
  ReviewTabChatOpened: 'ReviewTab ChatStep Opened',
  ReviewTabChatClosed: 'ReviewTab ChatStep Closed',
  ReviewTabChatAIQuesionReceived: 'ReviewTab ChatStep AI Quesion Received',
  ReviewTabChatUserAnswerSent: 'ReviewTab ChatStep User Answer Sent',
  ReviewTabListOpened: 'ReviewTab ListStep Opened',
  ReviewTabListClosed: 'ReviewTab ListStep Closed',
  ReviewTabReviewDocumentStarted: 'ReviewTab ReviewDocument Started',
  ReviewTabReviewDocumentResponseReceived:
    'ReviewTab ReviewDocument Response Received',
  ReviewTabClausesStepOpened: 'ReviewTab ClausesStep Opened',
  ReviewTabClausesStepClosed: 'ReviewTab ClausesStep Closed',
  ReviewTabClausesStepDismissClauses: 'ReviewTab ClausesStep Clause Dismissed',
  ReviewTabClausesStepKeepExistingClauses:
    'ReviewTab ClausesStep Clause kept existing',
  ReviewTabClausesStepInsertedClauses: 'ReviewTab ClausesStep Clause Inserted',
  ReviewTabClausesStepSuggestedAlternativeClauses:
    'ReviewTab ClausesStep Clause Suggested Alternative',
  ReviewTabClausesStepAcceptAllClauses:
    'ReviewTab ClausesStep Accepted all clauses',
  ReviewTabClausesStepFeedbackSubmitted:
    'ReviewTab ClausesStep Feedback Submitted',
  ReviewTabSuggestAlternativeChatAIQuesionReceived:
    'ReviewTab SuggestAlternativeChat AI Quesion Received',
  ReviewTabSuggestAlternativeChatUserAnswerSent:
    'ReviewTab SuggestAlternativeChat User Answer Sent',
};
