import React from 'react';
import { ProgressIndicator, Text } from '@fluentui/react';
import {
  Button,
  makeStyles,
  ProgressBar,
  shorthands,
} from '@fluentui/react-components';

interface ProgressBarProps {
  progress: number;
  //status: 'idle' | 'in-progress' | 'completed' | 'cancelled';
  message: string;
  taskName: string;
  estimatedTime: string;
  onCancel?: () => void;
}

export const StatusBar: React.FC<ProgressBarProps> = ({
  progress,
  //status,
  message,
  taskName,
  estimatedTime,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <>
      <div
        className={classes.card}
        style={{ opacity: progress === 100 ? '0.5' : '1' }}
      >
        <div className={classes.header}>
          <div className={classes.taskInfo}>
            <Text
              variant="smallPlus"
              styles={{ root: { color: '#605e5c', fontWeight: '600' } }}
            >
              {taskName}
            </Text>
            <Text variant="small" styles={{ root: { color: '#a19f9d' } }}>
              Est. time: {estimatedTime}
            </Text>
          </div>
          <Text variant="small" styles={{ root: { color: '#a19f9d' } }}>
            {message}
          </Text>
        </div>
        <ProgressIndicator
          percentComplete={progress / 100}
          styles={{
            progressBar: {
              backgroundColor: '#272727', // Fill color
              height: '10px',
              borderRadius: '5px',
            },
            progressTrack: {
              height: '10px',
              borderRadius: '5px',
            },
          }}
        />
      {onCancel && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            className={classes.cancelButton}
            onClick={onCancel}
            style={{ backgroundColor: 'red', color: 'white', marginTop: '20px' }}
          >
            Cancel
          </Button>
        </div>
      )}
      </div>
      <div className={classes.progressWrapper}></div>
    </>
  );
};

const useStyles = makeStyles({
  card: {
    ...shorthands.padding('16px'),
    backgroundColor: '#F5F5F5',
    ...shorthands.borderRadius('8px'),
    ...shorthands.transition('opacity', '0.5s', 'ease-in-out'),
    ...shorthands.margin('15px'),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  taskInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  progressWrapper: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.gap('8px'),
  },
  cancelButton: {
    width: '24px',
    height: '24px',
  },
});
