import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { Layout } from './layout/Layout';
import { setCurrentTab } from '../store/officeSlice';
import { AppDispatch, RootState } from '../store/store';
import { ActionsTabs, HomeTabs } from './features/review/components/const';
import {
  setDocument_Review_summary,
  setDocument_summary,
} from '../store/documentSlice';
import { getUserOfflineProcess } from '../store/activitySlice';
import {
  ContactSalesPage,
  LoginPage,
  SubscriptionPage,
} from 'qanoonisharedpackage';
import { tokenHelper } from '../helper/tokenHelper';
import HomeHeader from './shared/homeHeader/HomeHeader';
import { DraftPage } from './features/draft/DraftPage';
import ReviewTab from './features/review/ReviewTab';
import GlobalChange from './features/globalChanges/GlobalChange';
import HomeTab from './layout/HomePage';
import ActionsPage from './layout/ActionsPage';
import SummaryPage from './layout/SummaryPage';
import { setViewHeader } from '../store/reviewSlice';
export const HomePage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const homeTabRef = React.useRef(null); // Create a ref for the Home tab
  const currentTab = useSelector((state: RootState) => state.office.currentTab);
  const actionsTab = useSelector((state: RootState) => state.office.actionsTab);

  const displayGlobalChangesFlagValue = useSelector(
    (state: RootState) => state.featureFlag.displayGlobalChangesFlagValue
  );
  const displayDraftFlagValue = useSelector(
    (state: RootState) => state.featureFlag.displayDraftFlagValue
  );

  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const is_subscribe = useSelector(
    (state: RootState) => state.socket.is_subscribe
  );
  const is_subscription_expired = useSelector(
    (state: RootState) => state.socket.is_subscription_expired
  );
  const is_trial_expired = useSelector(
    (state: RootState) => state.socket.is_trial_expired
  );
  const token = useSelector((state: RootState) => state.socket.token);
  const isLogin = useSelector((state: RootState) => state.socket.isLogin);
  const styles = useStyles();

  useEffect(() => {
    dispatch(setDocument_Review_summary(undefined));
    dispatch(setDocument_summary(undefined));
    dispatch(setViewHeader(true));
  }, []);
  useEffect(() => {
    if (isConnected && isLogin) {
      dispatch(getUserOfflineProcess({}));
    }
  }, [isConnected, isLogin]);

  if (!isLogin)
    return (
      <div style={{ height: '100vh' }}>
        <LoginPage
          onClickBtn={() => {
            tokenHelper.onClickLogin();
          }}
          texts={[
            'Connect to your firm’s Data, through our Outlook Plugin and easy DMS integrations',
            'Use AI to Draft and Review Documents with your style and standards.',
            ' Use QCounsel as your legal search assistant across your firm’s information ',
          ]}
        />
      </div>
    );

  if (!is_subscribe) {
    if (is_trial_expired) {
      return <ContactSalesPage />;
    } else {
      return (
        <SubscriptionPage token={token} isExpired={is_subscription_expired} />
      );
    }
  }

  return (
    <Layout header={<HomeHeader />} footer={undefined}>
      <div className={styles.tabContent}>
        {currentTab === HomeTabs.Home && <HomeTab />}
        {currentTab === HomeTabs.Actions && actionsTab === undefined && (
          <ActionsPage />
        )}
        {currentTab === HomeTabs.Summary && <SummaryPage />}
        {currentTab === HomeTabs.Actions &&
          actionsTab === ActionsTabs.review && (
            <ReviewTab
              onReturn={() => {
                if (homeTabRef.current) {
                  homeTabRef.current.click(); // Trigger click on Home tab
                }
                dispatch(setCurrentTab(HomeTabs.Home));
              }}
            />
          )}
        {currentTab == HomeTabs.Actions &&
          actionsTab === ActionsTabs.draft &&
          displayDraftFlagValue && (
            <div>
              <DraftPage />
            </div>
          )}
        {currentTab == HomeTabs.Actions &&
          displayGlobalChangesFlagValue &&
          actionsTab === ActionsTabs.globalChanges && <GlobalChange />}
      </div>
    </Layout>
  );
};

const useStyles = makeStyles({
  menuList: {
    width: '112px !important',
    boxSizing: 'border-box',
    ...shorthands.border('0px'),
    ...shorthands.margin('0px'),
    minWidth: '112px !important',
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('1px'),
  },
  menuItem: {
    fontSize: '9px',
    ...shorthands.border('0px'),
    ...shorthands.margin('0px'),
    width: '100% !important',
    color: 'white',
    backgroundColor: '#2E2E2E',
    ...shorthands.borderRadius('0px'),

    '&:hover': {
      color: 'rgba(3, 135, 245, 1)',
      backgroundColor: '#2E2E2E',
    },
  },
  menuButton: {
    height: '26px',
    fontSize: '11px',
    color: 'rgba(59, 59, 59, 1)',
    justifyContent: 'space-between',
    width: '112px',
    backgroundColor: 'white',
  },
  menuPop: {
    width: '112px !important',
    boxSizing: 'border-box',
    ...shorthands.border('0px !important'),
    ...shorthands.margin('0px !important'),
    minWidth: '112px !important',
    backgroundColor: 'white',
  },
  homeHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    ...shorthands.padding('5px'),
    paddingBottom: '11px',
    ...shorthands.borderBottom('.6px', 'solid', 'rgba(183, 183, 183, 1)'),
    alignItems: 'center',
  },
  homeCnt: {
    display: 'flex',
    ...shorthands.gap('5px'),
    alignItems: 'center',
    fontSize: '11px',
    fontWeight: '400',
    cursor: 'pointer',
  },
  homeCntActive: {
    color: 'rgba(3, 135, 245, 1)',
    ...shorthands.borderBottom('1px', 'solid', 'rgba(3, 135, 245, 1)'),
    paddingBottom: '3px',
  },
  tabList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: '9px',
    paddingBottom: '12px',
    backgroundColor: '#fff',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: '#ccc',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.07)',
  },
  tabIcon: {
    color: '#3B3B3B',
  },
  selectedTabIcon: {
    color: '#0387f5',
  },
  selectedTabLabel: {
    fontSize: '12px',
    fontWeight: '400',
    color: '#0387f5',
  },
  tabLabel: {
    fontSize: '12px',
    color: '#3B3B3B',
  },
  tabContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundColor: 'white',
  },
});
