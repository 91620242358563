//---- Constants -----------------

import { GlobalChangePayload } from '../store/officeSlice';
import { DocumentService } from '../taskpane/features/draft/DocumentService';
import { DocumentData } from '../taskpane/features/draft/DocumentService';
import { DocumentServiceOOXML } from '../taskpane/features/draft/DocumentServiceOOXML';

export const OFFICE_ENCODING_COMMENT = '\u0005';

export enum ChangeTrackingMode {
  off = 'off',
  trackMyChanges = 'trackMyChanges',
  trackAllChanges = 'trackAllChanges',
}

export enum ChangeViewMode {
  editing = 'editing',
  viewing = 'viewing',
  tracking = 'tracking',
}

//--------------------------------------
export async function replaceDocumentContent(
  newContent: string
): Promise<void> {
  try {
    await Word.run(async function (context) {
      console.log(`Replacing document content with: ${newContent}`);
      const body = context.document.body;
      body.clear();

      if (isValidHtml(newContent)) {
        console.log('Content is valid HTML');
        body.insertHtml(newContent, 'End');
      } else {
        console.log('Inserting text instead of HTML');
        body.insertText(newContent, 'End');
      }

      await context.sync().then(function () {
        console.log('Content replaced successfully.');
      });

      console.log('New content added to the document body.');
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  } catch (error) {
    console.error(`Failed to insert text: ${error}`);
  }
}

export async function addDocumentContent(newContent: string): Promise<void> {
  try {
    await Word.run(async function (context) {
      console.log(`Adding new content to the document: ${newContent}`);
      const body = context.document.body;

      if (isValidHtml(newContent)) {
        console.log('Content is valid HTML');
        body.insertHtml(newContent, 'End');
      } else {
        console.log('Inserting text instead of HTML');
        body.insertText(newContent, 'End');
      }

      await context.sync().then(function () {
        console.log('Content added successfully.');
      });

      console.log('New content added to the document body.');
    }).catch(function (error) {
      console.log('Error: ' + JSON.stringify(error));
      if (error instanceof OfficeExtension.Error) {
        console.log('Debug info: ' + JSON.stringify(error.debugInfo));
      }
    });
  } catch (error) {
    console.error(`Failed to add text: ${error}`);
  }
}

export async function getDocumentContentAsHtml(): Promise<string> {
  return Word.run(async function (context) {
    const body = context.document.body;
    const bodyHtml = body.getHtml();

    await context.sync();

    console.log('Retrieved document content as HTML:', bodyHtml.value);
    return bodyHtml.value;
  }).catch(function (error) {
    console.error('Error retrieving document as HTML:', JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.error('Debug info:', JSON.stringify(error.debugInfo));
    }
    throw new Error(`Failed to retrieve document content as HTML: ${error}`);
  });
}
//----------------------------------------------------------------------------
// export async function lockWordDocumentFromTyping(): Promise<string> {
//     // Block editing by locking the entire document
//     return Word.run(async function (context) {
//       // Prevent adding new elements to the document
//       const docProtection = context.document.;
//       const options = Word.Application.DocumentPropertiesLoadOptions

//       context.document.body.contentControls.items.forEach((control) => {
//         control.cannotEdit = true;
//         control.cannotDelete = true;
//       });
//          // Set document protection to read-only
//          docProtection.set({
//           type: Word.DocumentProtectionType.readOnly
//         });

//       await context.sync();
//       return "Done"

//   }).catch(function (error) {
//     console.error('Error retrieving selection as Text:', JSON.stringify(error));
//     if (error instanceof OfficeExtension.Error) {
//       console.error('Debug info:', JSON.stringify(error.debugInfo));
//     }
//     throw new Error(`Failed to retrieve selection content as Text: ${error}`);
//   });
// }

//----------------------------------------------------------------------------
export async function getSelectedData(): Promise<string> {
  return Word.run(async function (context) {
    const body = context.document.getSelection();

    // Load the 'text' property of the 'body' object
    body.load('text');

    await context.sync();

    const text = body.text;

    return text;
  }).catch(function (error) {
    console.error('Error retrieving selection as Text:', JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.error('Debug info:', JSON.stringify(error.debugInfo));
    }
    throw new Error(`Failed to retrieve selection content as Text: ${error}`);
  });
}

export async function setViewMode(status: ChangeViewMode): Promise<any> {
  // Get whether the current view is edit or read.
  switch (status) {
    case ChangeViewMode.editing:
      Office.context.document.mode = Office.DocumentMode.ReadWrite;
      break;
    case ChangeViewMode.viewing:
      Office.context.document.mode = Office.DocumentMode.ReadOnly;
      break;
    case ChangeViewMode.tracking:
      setTrackingStatus(ChangeTrackingMode.trackMyChanges);
      break;
    default:
      Office.context.document.mode = Office.DocumentMode.ReadWrite;
      break;
  }
}

export async function setTrackingStatus(
  status: ChangeTrackingMode
): Promise<any> {
  return Word.run(async function (context) {
    switch (status) {
      case ChangeTrackingMode.off:
        context.document.changeTrackingMode = Word.ChangeTrackingMode.off;
        break;
      case ChangeTrackingMode.trackMyChanges:
        context.document.changeTrackingMode =
          Word.ChangeTrackingMode.trackMineOnly;
        break;
      case ChangeTrackingMode.trackAllChanges:
        context.document.changeTrackingMode = Word.ChangeTrackingMode.trackAll;
        break;
      default:
        context.document.changeTrackingMode = Word.ChangeTrackingMode.off;
        break;
    }
    await context.sync();
  }).catch(function (error) {
    console.error('Error retrieving selection as Text:', JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.error('Debug info:', JSON.stringify(error.debugInfo));
    }
    throw new Error(`Failed to retrieve selection content as Text: ${error}`);
  });
}

export async function replaceOriginalTextWithReplacementText(
  payload: GlobalChangePayload
): Promise<void> {
  try {
    const maxChunkSize = 255; // Define a reasonable chunk size limit

    // Function to split the text into chunks
    const splitIntoChunks = (text: string, size: number): string[] => {
      const chunks = [];
      for (let i = 0; i < text.length; i += size) {
        chunks.push(text.substring(i, i + size));
      }
      return chunks;
    };

    // Split the original clause into manageable chunks
    const originalChunks = splitIntoChunks(payload.original_clause, maxChunkSize);

    await Word.run(async context => {
      const body = context.document.body;

      for (const chunk of originalChunks) {
        const searchResults = body.search(chunk, {
          matchCase: true,
        });
        searchResults.load('text');
        await context.sync();

        if (searchResults.items.length > 0) {
          for (let i = 0; i < searchResults.items.length; i++) {
            const range = searchResults.items[i];
            range.insertText(payload.replacement_clause, 'Replace');
          }
          await context.sync();
        } else {
          console.log(`Chunk not found in document: ${chunk}`);
        }
      }
    });
  } catch (e) {
    console.log('Error applying global changes', e);
  }
}

//----------------------------------------------------------------------------
export async function getDocumentContentAsText(): Promise<string> {
  return Word.run(async function (context) {
    const body = context.document.body;

    // Load the 'text' property of the 'body' object
    body.load('text');

    await context.sync();

    const text = body.text;

    return text;
  }).catch(function (error) {
    console.error('Error retrieving document as Text:', JSON.stringify(error));
    if (error instanceof OfficeExtension.Error) {
      console.error('Debug info:', JSON.stringify(error.debugInfo));
    }
    throw new Error(`Failed to retrieve document content as Text: ${error}`);
  });
}
//---------------------------------------------------------------------------------------------------
function isValidHtml(htmlString: string): boolean {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const parserErrors = doc.querySelectorAll('parsererror');

  return parserErrors.length === 0;
}
//----------------------------------------------------------------------------------------------------------------------------

export async function getDocumentContentAsTextV2(): Promise<string> {
  return Word.run(async function (context) {
    const body = context.document.body;

    // Retrieve document content as HTML
    const htmlContent = body.getHtml();
    await context.sync();

    // Convert HTML to plain text
    const plainText = convertHtmlToPlainText(htmlContent.value);
    return plainText;
  }).catch(function (error) {
    console.error(
      'Error retrieving document content as plain text from HTML:',
      JSON.stringify(error)
    );
    if (error instanceof OfficeExtension.Error) {
      console.error('Debug info:', JSON.stringify(error.debugInfo));
    }
    throw new Error(
      `Failed to retrieve document content as plain text from HTML: ${error}`
    );
  });
}
export async function clearDocumentContent(): Promise<void> {
  try {
    await Word.run(async function (context) {
      const body = context.document.body;
      body.clear();
    });
  } catch (error) {
    console.error(`Failed to clear document content: ${error}`);
    if (error instanceof OfficeExtension.Error) {
      console.error('Debug info:', JSON.stringify(error.debugInfo));
    }
    throw new Error(`Failed to clear document content: ${error}`);
  }
}
//------------------------------------------------------------------------------------
// Helper function to convert HTML to plain text
function convertHtmlToPlainText(htmlContent: string): string {
  // Replace <br> and <p> tags with single newlines to maintain structure
  let textContent = htmlContent.replace(/<br\s*\/?>/gi, '\n');
  textContent = textContent.replace(/<\/p>/gi, '\n');

  // Handle specific cases for lists (<li> tags)
  textContent = textContent.replace(/<li>/gi, '\n- ');
  textContent = textContent.replace(/<\/li>/gi, '');

  // Remove all other HTML tags while keeping visible text
  textContent = textContent.replace(/<[^>]+>/g, '');

  // Decode HTML entities
  const htmlEntities: { [key: string]: string } = {
    '&nbsp;': ' ',
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
  };
  textContent = textContent.replace(
    /&[a-z]+;/gi,
    entity => htmlEntities[entity] || entity
  );

  // Remove all extra blank lines (consolidate multiple blank lines)
  textContent = textContent.replace(/\n\s*\n+/g, '\n');

  // Trim leading/trailing whitespace
  return textContent.trim();
}

export const getDocumentPath = async (): Promise<string> => {
  return new Promise((resolve) => {
    Office.context.document.getFilePropertiesAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value.url || '');
      } else {
        resolve('');
      }
    });
  });
}

export async function getDocumentName() {
  return new Promise((resolve, reject) => {
    Office.context.document.getFilePropertiesAsync(asyncResult => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value.url); // Return the document name
      } else {
        reject(asyncResult.error.message); // Return the error message
      }
    });
  });
}

export const insertDocxFromBase64 = async (base64File: any): Promise<void> => {  //string
  console.log('Inserting document content');
  try {
    // await Word.run(async (context) => {
    //   // Clear existing content first
    //   const body = context.document.body;
    //   body.clear();
    //   await context.sync();
    // await Word.run(async (context) => {
    //   // Clear existing content first
    //   const body = context.document.body;
    //   body.clear();
    //   await context.sync();
      
    //   // Insert base64 content
    //   body.insertFileFromBase64(base64File, Word.InsertLocation.replace);
    //   await context.sync();
    // });
    // console.log('Document content replaced successfully');

    // Build document from content and style structure
    console.log('Building document from content and style structure');
    console.log("content_and_styles_structure", base64File);
    const contentStructure = base64File.content;
    const styleStructure = base64File.styles;
    const templateStructure = base64File.templateBase64;
    await buildWordDocumentFromStructure(contentStructure, styleStructure, templateStructure);
    // End build document from content and style structure

  } catch (error) {
    console.error('Error replacing document content:', error);
    throw error;
  }
};

// Lloyds approach
// export const insertDocxFromBase64 = async (base64File: string): Promise<void> => {
//   console.log('Inserting document content');
//   try {
//     await Word.run(async (context) => {
//       // This assumes you have the document content as a base64-encoded string
//       const myNewDoc = context.application.createDocument(base64File);
//       await context.sync();
//       myNewDoc.open();
//       await context.sync();
//     }).catch(function (error) {
//       console.log("Error: " + error);
//     });
//   } catch (error) {
//     console.error('Error inserting document:', error);
//   }
// };

export async function refreshDocument() {
  try {
      await Word.run(async (context) => {
          await context.sync();
          console.log("Document refreshed successfully!");
      });
  } catch (error) {
      console.error("Error refreshing document:", error);
  }
}

export async function refreshWordDocument() {
  try {
      await Word.run(async (context) => {
          // Trigger a refresh by modifying and syncing the document
          const body = context.document.body;
          body.insertParagraph("", Word.InsertLocation.end); // Dummy operation
          await context.sync();
      });
      console.log("Document refreshed successfully!");
  } catch (error) {
      console.error("Error refreshing document:", error);
  }
}


export async function replaceContent(parsedContent: any) {
  try {
      await Word.run(async (context) => {
          const body = context.document.body;

          // Clear existing content
          body.clear();

          // Insert new content paragraph-by-paragraph
          parsedContent.forEach((item) => {
              const paragraph = body.insertParagraph(item.text, Word.InsertLocation.end);
              paragraph.style = item.style; // Apply the style to each paragraph
          });

          await context.sync();
          console.log("Content replaced successfully!");
      });
  } catch (error) {
      console.error("Error replacing content:", error);
  }
}

export async function insertRawDocContent(rawDocBinary: any) {
  try {
      await Word.run(async (context) => {
          // Clear the existing content
          context.document.body.clear();

          // Create a temporary blob from the raw binary
          const blob = new Blob([rawDocBinary], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" });

          // Create a URL for the blob
          const blobUrl = URL.createObjectURL(blob);

          // Use the Word API to insert the content of the .docx file
          const body = context.document.body;
          await body.insertFileFromBase64(blobUrl, Word.InsertLocation.replace);

          await context.sync();
          console.log("Document content inserted successfully without conversion!");
      });
  } catch (error) {
      console.error("Error inserting raw document content:", error);
  }
}

export const buildWordDocumentFromStructure = async (
  content_structure: any,
  style_structure: any,
  template_base64: any
): Promise<void> => {
  try {
    console.log("Building document with:", {
      content: content_structure,
      styles: style_structure
    });

    const docData: DocumentData = {
      styles: style_structure || {},
      content: content_structure || {},
      templateBase64: template_base64 || {}
    };

    await DocumentService.buildDocument(docData);
    // await DocumentServiceOOXML.buildDocument(docData);
    console.log('Document built successfully');
  } catch (error) {
    console.error('Error in buildWordDocumentFromStructure:', error);
    console.error('Content structure:', JSON.stringify(content_structure, null, 2));
    console.error('Style structure:', JSON.stringify(style_structure, null, 2));
    console.error('Template structure:', JSON.stringify(template_base64, null, 2));
    throw error;
  }
};
