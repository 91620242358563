import React from 'react';
import { makeStyles, shorthands, Button } from '@fluentui/react-components';
import { DismissCircleRegular } from '@fluentui/react-icons';

export const ConfirmationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onCancelButtonClick: () => void;
}> = ({ isOpen, onClose, onCancelButtonClick }) => {
  const classes = useStyles();

  if (!isOpen) return null;

  return (
    <div className={classes.backdrop}>
      <div
        className={classes.popup}
        style={{ animation: 'fadeIn 0.3s ease-out' }}
      >
        <div className={classes.header}>
          <DismissCircleRegular
            className={classes.closeIcon}
            onClick={onClose}
            aria-label="Close"
          />
        </div>
        <div className={classes.content}>
          <p>
            Are you sure you want to cancel draft generation?
          </p>
        </div>
        <div className={classes.actions}>
          <Button appearance="secondary" onClick={onClose}>
            No
          </Button>
          <Button appearance="primary" onClick={onCancelButtonClick}>
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: '0',
    ...shorthands.margin('auto'),
    width: '92%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popup: {
    backgroundColor: '#272727',
    color: '#fff',
    maxWidth: '400px',
    width: '90%',
    ...shorthands.borderRadius('12px'),
    ...shorthands.padding('20px'),
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.4)',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: '20px',
    color: '#fff',
    '&:hover': {
      color: '#bbbbbb',
    },
  },
  content: {
    fontSize: '15px',
    lineHeight: '1.6',
    marginBottom: '20px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    ...shorthands.gap('10px'),
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
});
