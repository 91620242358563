import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { RootState, AppDispatch } from '../../../store/store';
import { sendStopDraftGenerationRequest, setChatMessages, setDraftResponseChunk, setIsDocumentSessionsStarted, setIsEssentialInformationComplete, setIsGeneratingDraft, setIsLoadingListMandatoryQuestionsSubset } from '../../../store/draftSlice';
import { sendMandatoryQuestionsSubsetRequest } from '../../../store/draftSlice';
import { setReviewStepNumber, setViewHeader } from '../../../store/reviewSlice';
import { MessageRole } from '../../../models/chat';
import { ChatMessage } from '../../../models/chat';
import BotChating from '../../shared/botChating/BotChating';
import { DraftDocumentResponseChunk } from '../../../models/draft';
import { Toast, ToastTitle, useOptionStyles_unstable, useToastController } from '@fluentui/react-components';
import { GLOBAL_TOAST_ID } from '../../layout/Layout';
import { applyGlobalChanges, applyGlobalChangesV2ForDraft, checkDocumentHasContent, clearSuggestions, GlobalChangePayload, loadWordDocument, setActionsTab, setIsAnalyzing, setPreventAnalyzing } from '../../../store/officeSlice';
import { ActionsTabs, ReviewSteps } from '../review/components/const';
import { sendMessage } from '../../../store/socketSlice';
import { ChangeTrackingMode, clearDocumentContent, getSelectedData, replaceOriginalTextWithReplacementText, setTrackingStatus } from '../../../helper/office';
import { ResponseEnum, StatusEnum } from '../../../models/base';

  export const initalDraftChunkResponse: DraftDocumentResponseChunk = {
    action: ResponseEnum.DraftDocumentResponseChunk,
    status: StatusEnum.Success,
    errorMessage: null,
    response: "",
    document_text: "",
    chunk_index: 1,
    total_chunks: 10,
    is_complete: false,
    task_id: null,
    estimated_time: null
  }

enum YesOptionEnum {
  GENERATE_AGAIN = 'GENERATE_AGAIN',
  INITAL_QUESTIONS_DONE = 'INITAL_QUESTIONS_DONE',
  alteration_action = 'alteration_action',
}

enum NoOptionEnum {
  GENERATE_AGAIN = 'GENERATE_AGAIN',
  INITAL_QUESTIONS_DONE = 'INITAL_QUESTIONS_DONE',
  alteration_action = 'alteration_action',
}

const DraftChat = ({sendPayload}) => {
  const [value, setValue] = useState('');
  const currentSequence = useRef<number>(2);
  const currentQuestionSet = useRef<number>(1);
  const [captureLastUserResponse, setCaptureLastUserResponse] =
    useState<string>('');
  const [isCapturedLastUserResponse, setIsCapturedLastUserResponse] =
    useState<boolean>(false);
  const [isAllowedToShiftToModifications, setIsAllowedTOShiftToModifications] = useState<boolean>(false);
  const [probabilityValue, setProbabilityValue] = useState(0);
  const { dispatchToast } = useToastController(GLOBAL_TOAST_ID);
  const [yesQuestion, setYesQuestion] = useState<YesOptionEnum>();
  const [noQuestion, setNoQuestion] = useState<NoOptionEnum>();
  const [task_id, setTaskId] = useState<string>('');
  const [recievedEstimatedTime, setRecievedEstimatedTime] = useState<string>('5 min')
  const [countDown, setCountDown] = useState<string>('')

  const dispatch = useDispatch<AppDispatch>();
  const chatMessages: ChatMessage[] = useSelector(
    (state: RootState) => state.draft.chatMessages
  );
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  const mandatoryQuestions = useSelector(
    (state: RootState) => state.draft.mandatoryQuestionsSubset
  );
  const isLoadingListMandatoryQuestions: boolean = useSelector(
    (state: RootState) => state.draft.isLoadingListMandatoryQuestionsSubset
  );

  const isGeneratingDraft: boolean = useSelector(
    (state: RootState) => state.draft.isGeneratingDraft
  );

  const draftChunkResponse: DraftDocumentResponseChunk = useSelector(
    (state: RootState) => state.draft.draftChunkResponse
  );
  const documentId = useSelector((state: RootState) => state.office.documentId);
  const isDocumentSessionStarted = useSelector((state: RootState) => state.draft.isDocumentSessionStarted);
  const calculateProgressProbability = (current: number, total: number) => {
    return current / total;
  }
  
  useEffect(() => {
    const interval = setInterval(() => {
      // If countdown is at 0, use received time, otherwise continue countdown
      const timeToUse = countDown === "0m 0s" ? recievedEstimatedTime : countDown;
      
      // Parse minutes and seconds, handling various time formats
      let minutes = 0, seconds = 0;
      const timeParts = timeToUse.split(' ');
      timeParts.forEach(part => {
        if (part.endsWith('m')) minutes = parseInt(part) || 0;
        if (part.endsWith('s')) seconds = parseInt(part) || 0;
      });
      
      let totalSeconds = minutes * 60 + seconds;

      if (totalSeconds > 0) {
        totalSeconds -= 1;
        const newMinutes = Math.floor(totalSeconds / 60);
        const newSeconds = totalSeconds % 60;

        setCountDown(`${newMinutes}m ${newSeconds}s`);
      } else {
        setCountDown("0m 0s");
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [recievedEstimatedTime, countDown]); // Add countDown to dependencies
  
  // const reviewDocument = () => {
  //   dispatch(setPreventAnalyzing(false));
  //   dispatch(setIsAnalyzing(true));
  //   dispatch(clearSuggestions());
  //   dispatch(
  //     sendMessage({
  //       action: 'DocumentReviewRequestV2',
  //       user_document_id: documentId,
  //     })
  //   );
  // };
  
  const sendBotMessage = (message: string, is_yes_no: boolean = false) => {
    var botMessage: ChatMessage = {
      message: message,
      role: MessageRole.Assistant,
      id: uuidv4(),
      isChunk: false,
      is_yes_no: is_yes_no,
      attached: '',
    };
    dispatch(setChatMessages([...chatMessages, botMessage]));
  }
  
  useEffect(() => {
    console.log("Updated messages", chatMessages)
  }, [chatMessages])
  
  useEffect(() => {
    if(draftChunkResponse?.task_id){
      setTaskId(draftChunkResponse?.task_id)
    }
  }, [draftChunkResponse?.task_id])
  
  const disableLastYesNoOptionsAndSendSelectedOption = (message: string) => {
    const updatedMessages = [...chatMessages];
    const regenerateIndex = updatedMessages.findIndex(msg => msg.message === "Would you like to regenerate the draft?");
    if (regenerateIndex !== -1) {
      const regenerateMessage = { ...updatedMessages[regenerateIndex] };
      if (regenerateMessage.is_yes_no === true) {
        regenerateMessage.is_yes_no = false;
        updatedMessages[regenerateIndex] = regenerateMessage;
      }
    }
    setYesQuestion(null);
    setNoQuestion(null);
    dispatch(setChatMessages(updatedMessages));
    sendBotMessage(message);
    // console.log("Updated messages", updatedMessages)
  }

  const onYes = async() => {
      disableLastYesNoOptionsAndSendSelectedOption('Yes');
      switch (yesQuestion) {
        case YesOptionEnum.alteration_action:
          const payload: GlobalChangePayload = {
            original_clause: mandatoryQuestions.original_clause,
            replacement_clause: mandatoryQuestions.response,
          };
          dispatch(applyGlobalChangesV2ForDraft(payload));
          break
        case YesOptionEnum.GENERATE_AGAIN:
          await clearDocumentContent();
          sendPayload()
          break;
        default:
          var message = 'We are done with the draft generation process!';
          sendBotMessage(message);
          break;
      }
  }
  
  const onNo = async() => {
    disableLastYesNoOptionsAndSendSelectedOption('No');
    var message = ''
    switch(noQuestion)
    {
        case NoOptionEnum.alteration_action:
          const retainedInfo = mandatoryQuestions?.retained_info ? JSON.parse(JSON.stringify(mandatoryQuestions.retained_info)) : {};
          const selectedData = await getSelectedData();
          console.log("Selected Data: ", selectedData);
          await getQuestionsInSubset(currentQuestionSet.current, currentSequence.current, value, retainedInfo);
        default:
          var message = 'We are done with the draft generation process!';
          sendBotMessage(message);
          break;
    }
  }
  
  useEffect(() => {
    const fetchInitialQuestion = async () => {
      if(chatMessages.length == 0)
      {
        const initialQuestion = 'Hi, how can I help you with your drafting needs?';
        sendBotMessage(initialQuestion)
      }
    };
    fetchInitialQuestion();
  }, [chatMessages]);

  useEffect(() => {
    const updateOnResponse = async() => {
      if (draftChunkResponse?.estimated_time && draftChunkResponse?.estimated_time != null) {
        const totalSeconds = draftChunkResponse.estimated_time;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds - (minutes * 60));

        console.log("Estimated time for draft response chunk",draftChunkResponse.estimated_time)
        setRecievedEstimatedTime(`${minutes}m ${seconds}s`);
        // Log the received estimated time for debugging purposes
        console.log("Recieved estimated time",recievedEstimatedTime)
      } else {
        setRecievedEstimatedTime("0m 0s");
      }
      if(isGeneratingDraft == true && draftChunkResponse)
      {
        var probabilityValue = calculateProgressProbability(draftChunkResponse.chunk_index, draftChunkResponse.total_chunks);
        probabilityValue = Number((probabilityValue * 100).toFixed(0))
        setProbabilityValue(probabilityValue);
      }
      else if(isGeneratingDraft == false)
      {
        dispatch(setIsGeneratingDraft(null));
        dispatch(setDraftResponseChunk(initalDraftChunkResponse));
        dispatchToast(
          <Toast>
            <ToastTitle>Draft Generated Successfully!</ToastTitle>
          </Toast>,
          { position: 'bottom-end', intent: 'success' }
        );
        currentSequence.current = 4;
        await getQuestionsInSubset(currentQuestionSet.current, currentSequence.current, `This is the ${mandatoryQuestions.retained_info['governing_law']}`, mandatoryQuestions.retained_info);
      }
    }
    updateOnResponse()
  }, [draftChunkResponse])
  
  // const incrementQuestionSet = async() => {
  //   currentQuestionSet.current += 1;
  //   await getMandatoryQuestionsInSubset(currentQuestionSet.current, currentSequence.current, value, mandatoryQuestions.retained_info);
  // }
  
  useEffect(() => {
    const handleMandatoryQuestions = async () => {
      if (
        !isLoadingListMandatoryQuestions &&
        mandatoryQuestions?.question != null
      ) {
        console.log("These are the mandaotry questions: ", mandatoryQuestions);
        const is_yes_no = mandatoryQuestions.type === 'yes_no';
        if(is_yes_no){
          setYesQuestion(YesOptionEnum[mandatoryQuestions.subtype as keyof typeof YesOptionEnum]);
          setNoQuestion(NoOptionEnum[mandatoryQuestions.subtype as keyof typeof NoOptionEnum]);
        }
        if(mandatoryQuestions?.subtype == 'alteration_action')
        {
          await setTrackingStatus(ChangeTrackingMode.trackMyChanges);
          const payload: GlobalChangePayload = {
            original_clause: mandatoryQuestions.original_clause,
            replacement_clause: mandatoryQuestions.response,
          };
          await replaceOriginalTextWithReplacementText(payload);
        }
        else if(mandatoryQuestions?.subtype == 'communicative_action')
        {
          sendBotMessage(mandatoryQuestions.response, is_yes_no)
        }
        else
        {
          sendBotMessage(mandatoryQuestions.question, is_yes_no)
        }
        currentSequence.current = mandatoryQuestions.current_sequence;
      }
    };

    handleMandatoryQuestions();
  }, [isLoadingListMandatoryQuestions]);
  
  const handleMandatoryQuestionsChange = () => {
    const retainedInfo = mandatoryQuestions?.retained_info ? JSON.parse(JSON.stringify(mandatoryQuestions.retained_info)) : {};
    const isKeyInformationComplete = verifyKeyInformation(retainedInfo);
    if (isKeyInformationComplete) {
      dispatch(setIsEssentialInformationComplete(true));
    } else {
      dispatch(setIsEssentialInformationComplete(false));
    }
  };

  useEffect(() => {
    handleMandatoryQuestionsChange();
  }, [mandatoryQuestions]);
  
  const verifyKeyInformation = (retainedInfo) => {
    if(!isLoadingListMandatoryQuestions)
    {
        if (Object.keys(retainedInfo).length === 0) {
          return false;
        }
        for (const key in retainedInfo) {
          if (retainedInfo.hasOwnProperty(key)) {
            console.log("This is the key", retainedInfo[key]);
            if (retainedInfo[key] === "" || retainedInfo[key] === null || retainedInfo[key] === undefined) {
              return false;
            }
          }
        }
        return true;
    }
    else
    {
      return false;
    }
  }


  const onStopDraftGeneration = () => {
    sendBotMessage("Stopping Draft Generation... Please Wait.")
    dispatch(setIsGeneratingDraft(true));
    dispatch(sendStopDraftGenerationRequest(task_id));
  };

  const onMessageSend = async () => {
    if (isConnected && value.trim() && !isLoadingListMandatoryQuestions && value.trim().length > 0) {
      const userMessage: ChatMessage = {
        message: value,
        role: MessageRole.User,
        id: uuidv4(),
        isChunk: false,
        attached: '',
      };
      dispatch(setChatMessages([...chatMessages, userMessage]));
      setValue('');
      const retainedInfo = mandatoryQuestions?.retained_info ? JSON.parse(JSON.stringify(mandatoryQuestions.retained_info)) : {};
      const selectedData = await getSelectedData();
      console.log("Selected Data: ", selectedData);
      await getQuestionsInSubset(currentQuestionSet.current, currentSequence.current, value, retainedInfo);
      const isKeyInformationComplete = verifyKeyInformation(retainedInfo)
      if(isKeyInformationComplete)
      {
        dispatch(setIsEssentialInformationComplete(true))
      }
      else
      {
        dispatch(setIsEssentialInformationComplete(false))
        return;
      }
    }
  };

  const getQuestionsInSubset = async (
    question_id: number,
    current_sequence: number,
    earlier_user_response: string,
    retained_info: any
  ) => {
    if (earlier_user_response.length > 0) {
      const clause = await getSelectedData();
      const resultAction = await dispatch(
        sendMandatoryQuestionsSubsetRequest({
          question_id,
          current_sequence,
          earlier_user_response,
          retained_info,
          clause
        })
      );
      if (sendMandatoryQuestionsSubsetRequest.fulfilled.match(resultAction)) {
        console.log('Mandatory Questions:', mandatoryQuestions);
        
      } else {
        console.error('Failed to get mandatory questions:', resultAction.payload);
      }
    }
    else
    {
      console.log("No user response");
    }
  };

  useEffect(() => {
    dispatch(setViewHeader(true));
  }, []);

  return (
    <div>
      <BotChating
        loading={isLoadingListMandatoryQuestions || isGeneratingDraft}
        onYes={onYes}
        onNo={onNo}
        onMessageSend={onMessageSend}
        isShowProgressBar={isGeneratingDraft}
        progressBarValue={probabilityValue}
        isShowProgressBarLabel={true}
        progressBarLabel={'Generating Draft... ' + probabilityValue + '%'}
        chatMessages={chatMessages}
        value={value}
        setValue={setValue}
        taskName='Draft Generation'
        estimatedTime={countDown || '5 minutes'}
        messageBoxHeight='100px'
        messageBoxWidth='100%'
        onCancelJob={onStopDraftGeneration}
      />
    </div>
  );
};

export default DraftChat;
