import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { sendMessage } from './socketSlice';
import { RootState } from './store';
import { RequestEnum } from '../models/base';
import {
  MessageRole,
  ReviewChatMessage,
  ReviewDocumentChatResponse,
  ReviewDocumentChatRequest,
  isReviewDocumentChatResponseChunk,
  SuggestChatMessage,
  ReviewDocumentSuggestChatRequest,
  SuggestAlternativeChatResponse,
} from '../models/chat';

import { getDocumentContentAsText } from '../helper/office';
import {
  PosthogEventsNames,
  ReviewSteps,
} from '../taskpane/features/review/components/const';
import posthog from 'posthog-js';

interface ReviewChatState {
  chatMessages: ReviewChatMessage[];
  chatThreadId?: string;
  chatThreadId2?: string;
  viewHeader?: boolean;
  chatSuggestMessages: SuggestChatMessage[];
  chatingLoading?: boolean;
  reviewStepNumber: number;
  party?: string;
  additional_instructions?: string;
  reviewProgress?: number;
  reviewStatus?: string;
}

const initialState: ReviewChatState = {
  chatMessages: [],
  viewHeader: true,
  chatSuggestMessages: [],
  chatingLoading: false,
  reviewStepNumber: ReviewSteps.chat,
  party: '',
  additional_instructions: '',
  reviewProgress: 0,
  reviewStatus: '',
};

let messageCount = 0;
export const sendChatMessage = createAsyncThunk(
  'review/sendContextMessage',
  async (newMessage: ReviewChatMessage, { dispatch, getState }) => {
    dispatch(setchatingLoading(true));
    const state = getState() as RootState;
    const { chatMessages } = state.review;
    const { chatThreadId } = state.review;
    messageCount++;

    if (messageCount === 2) {
      const party = newMessage.message;
      dispatch(setParty(newMessage.message));
      console.log('Party:', party);
    }
    if (messageCount === 3 ) {
      const chatinstructions = newMessage.message;
      dispatch(setChatInstructions(chatinstructions));
      console.log(' Chat Instructions:', chatinstructions);
    }
    
    const updatedMessages = [
      ...chatMessages,
      newMessage,
      // loading indicator message
      {
        id: uuidv4(),
        role: MessageRole.Assistant,
        message: '...',
        isChunk: true,
      },
    ];

    dispatch(setChatMessages(updatedMessages));

    const documentText = await getDocumentContentAsText();
    const documentId = state.office.documentId;
    console.log('Document ID:', documentId);

    const chatMessageRequest: ReviewDocumentChatRequest = {
      action: RequestEnum.ReviewDocumentChatRequest,
      thread_id: chatThreadId,
      messages: [...chatMessages, newMessage],
      document_text: documentText,
      document_id: documentId,
    };

    dispatch(sendMessage(chatMessageRequest));
  }
);

export const handleReviewChatMessageResponse = createAsyncThunk(
  'review/handleReviewChatMessageResponse',
  async (response: ReviewDocumentChatResponse, { dispatch, getState }) => {
    const { review, office } = getState() as RootState;
    const { chatMessages } = review;
    const isChunk = isReviewDocumentChatResponseChunk(response);
    const newMessage: ReviewChatMessage = {
      id: uuidv4(),
      role: MessageRole.Assistant,
      message: response.response,
      isChunk,
      is_finished: response.is_finished,
    };

    const shouldReplaceLastMessage =
      chatMessages.length > 0 && chatMessages[chatMessages.length - 1].isChunk;

    let updatedMessages = shouldReplaceLastMessage
      ? [...chatMessages.slice(0, -1), newMessage]
      : [...chatMessages, newMessage];
    let user_id = chatMessages[0].user_id;
    let sessionId = chatMessages[0].sessionId;
    let stepSessionId = chatMessages[0].stepSessionId;
    if (!isChunk) {
      posthog.capture(PosthogEventsNames.ReviewTabChatAIQuesionReceived, {
        distinct_id: user_id, // Use a unique identifier for the user/session
        time: new Date().toISOString(),
        session_id: sessionId,
        stepSessionId: stepSessionId,
        tab_name: 'review',
        source: 'word',
        review_step: 'chat',
        question: (response as any).response,
        documentId: office.documentId,
      });
    }

    dispatch(setChatMessages(updatedMessages));
    dispatch(setChatThreadId(response.thread_id));
    dispatch(setchatingLoading(false));
  }
);
//---------------------------------------------------------------------------
export const sendSuggestChatMessage = createAsyncThunk(
  'review/sendSuggestAltContextMessage',
  async (
    {
      newMessage,
      clause,
      ai_clause,
    }: {
      newMessage: SuggestChatMessage;
      clause: string;
      ai_clause: string;
    },
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const { chatSuggestMessages } = state.review;
    const { chatThreadId2 } = state.review;

    const updatedMessages = [
      ...chatSuggestMessages,
      newMessage,
      // loading indicator message
      {
        id: uuidv4(),
        role: MessageRole.Assistant,
        message: '...',
        isChunk: true,
      },
    ];

    dispatch(setchatSuggestMessages(updatedMessages));

    const documentText = await getDocumentContentAsText();

    const chatMessageRequest: ReviewDocumentSuggestChatRequest = {
      action: RequestEnum.SuggestAlternativeChatRequest,
      thread_id: chatThreadId2,
      messages: [...chatSuggestMessages, newMessage],
      document_text: documentText,
      current_clause: clause ?? 'it is a missing clause ',
      ai_suggested_clause: ai_clause ? ai_clause : 'no suggestion yet',
    };
    console.log('Sending a new chat message:', newMessage);
    dispatch(sendMessage(chatMessageRequest));
    dispatch(setchatingLoading(true));
  }
);
/////////////////-------------------------------------------
export const handleReviewChatSuggestAltMessageResponse = createAsyncThunk(
  'review/handleReviewChatSuggestAlternativeMessageResponse',
  async (response: SuggestAlternativeChatResponse, { dispatch, getState }) => {
    const { review, office } = getState() as RootState;
    const { chatSuggestMessages } = review;
    //const isChunk = isReviewDocumentChatResponseChunk(response);
    const newMessage: SuggestChatMessage = {
      id: uuidv4(),
      role: MessageRole.Assistant,
      message: response.response,
      isChunk: false,
      is_question: response.is_question,
      is_suggestion_message: response.is_suggestion_message,
    };

    /*     const shouldReplaceLastMessage =
      chatSuggestMessages.length > 0 &&
      chatSuggestMessages[chatSuggestMessages.length - 1].isChunk;

    let updatedMessages = shouldReplaceLastMessage
      ? [...chatSuggestMessages.slice(0, -1), newMessage]
      : [...chatSuggestMessages, newMessage];


 */
    let user_id = chatSuggestMessages[0].user_id;
    let sessionId = chatSuggestMessages[0].sessionId;
    let stepSessionId = chatSuggestMessages[0].stepSessionId;
    posthog.capture(
      PosthogEventsNames.ReviewTabSuggestAlternativeChatAIQuesionReceived,
      {
        distinct_id: user_id, // Use a unique identifier for the user/session
        time: new Date().toISOString(),
        session_id: sessionId,
        stepSessionId: stepSessionId,
        tab_name: 'review',
        source: 'word',
        review_step: 'clauses',
        answer: response.response,
        documentId: office.documentId,
        chat_type: 'suggest_alternative',
      }
    );

    let updatedMessages = [...chatSuggestMessages.slice(0, -1), newMessage];
    dispatch(setchatSuggestMessages(updatedMessages));
    dispatch(setChatThreadId2(response.thread_id));
    dispatch(setchatingLoading(false));
  }
);
export const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    // CHAT
    setChatMessages: (state, action: PayloadAction<ReviewChatMessage[]>) => {
      state.chatMessages = action.payload;
    },
    setchatSuggestMessages: (
      state,
      action: PayloadAction<SuggestChatMessage[]>
    ) => {
      state.chatSuggestMessages = action.payload;
    },
    setChatThreadId: (state, action: PayloadAction<string>) => {
      state.chatThreadId = action.payload;
    },
    setChatThreadId2: (state, action: PayloadAction<string>) => {
      state.chatThreadId2 = action.payload;
    },
    setViewHeader: (state, action: PayloadAction<boolean>) => {
      state.viewHeader = action.payload;
    },
    setchatingLoading: (state, action: PayloadAction<boolean>) => {
      state.chatingLoading = action.payload;
    },
    setReviewStepNumber: (state, action: PayloadAction<number>) => {
      state.reviewStepNumber = action.payload;
    },
    setParty: (state, action: PayloadAction<string>) => {
      state.party = action.payload;
    },
    setReviewProgress: (state, action: PayloadAction<number>) => {
      state.reviewProgress = action.payload;
    },
    setReviewStatus: (state, action: PayloadAction<string>) => {
      state.reviewStatus = action.payload;
    },
    setChatInstructions: (state, action: PayloadAction<string>) => {
      state.additional_instructions = action.payload;
    },
  },
});

export const {
  // chat
  setChatMessages,
  setChatThreadId,
  setChatThreadId2,
  setViewHeader,
  setchatSuggestMessages,
  setchatingLoading,
  setReviewStepNumber,
  setParty,
  setChatInstructions,
  setReviewProgress,
  setReviewStatus,
} = reviewSlice.actions;

export default reviewSlice.reducer;
