import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import './index.css';
import { Provider } from 'react-redux';
import store, { persister } from '../store/store';
import { HomePage } from './App';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

const rootElement: HTMLElement = document.getElementById('container');
const root = createRoot(rootElement);
const customTheme = {
  ...webLightTheme,
  fontFamilyBase: "'Arial', Helvetica, sans-serif", // Custom font
};
posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  autocapture: {
    // url_ignorelist can be used on its own, or combined with url_allowlist to further filter which URLs are captured
    element_allowlist: [],
  },
});

/* Render application after Office initializes */
Office.onReady(async () => {
  console.log('Rendering the React application...');
  renderApp();
});

function renderApp() {
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <FluentProvider theme={customTheme}>
          <PostHogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
            client={posthog}
          >
            <Router>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Router>
          </PostHogProvider>
        </FluentProvider>
      </PersistGate>
    </Provider>
  );
}

/* Hot Module Replacement (HMR) setup */
if ((module as any).hot) {
  (module as any).hot.accept(() => {
    renderApp();
  });
}
