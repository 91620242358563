import { Button } from '@fluentui/react-components';
import { ArrowRotateCounterclockwiseRegular } from '@fluentui/react-icons';
import React from 'react';
import styles from '../ReviewTab.module.css';
import posthog from 'posthog-js';
import { v4 as uuidv4 } from 'uuid';

posthog.init('phc_G5jB2LpH1Lrl0sItgXMPw1ptJhiCYKlJdk7GQMQ65Y', { api_host: 'https://eu.i.posthog.com' , person_profiles: 'always'});
const steps = {
  initial: 1,
  chat: 2,
  review: 3,
};

const IntialPage = ({ setStepNo }: any) => {
  posthog.capture('Review Function Used', {
    distinct_id: uuidv4(), // Use a unique identifier for the user/session
  });
  return (
    <div className={styles.btnCnt}>
      <Button
        //disabled={isAnalyzing || !isConnected} // Disable if WebSocket is not connected
        icon={<ArrowRotateCounterclockwiseRegular color="white" />}
        //onClick={reviewDocument}
        className={styles.reviewBtn}
        onClick={() => setStepNo(steps.chat)}
      >
        Review Document
      </Button>
      <div>Clause by Clause Analysis</div>
    </div>
  );
};

export default IntialPage;
