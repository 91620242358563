import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BlobServiceClient } from '@azure/storage-blob';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { sendMessage } from './socketSlice';
import { RootState } from './store';

import {
  Contract,
  CreateTemplateRequest,
  CreateTemplateResponse,
  DeleteTemplateRequest,
  DeleteTemplateResponse,
  DraftDocumentRequest,
  DraftDocumentResponseChunk,
  FindAndIterateSubsetQuestionsRequest,
  ListContractsRequest,
  ListTemplatesRequest,
  QuestionSubset,
  StopDraftGenerationRequest,
  StopDraftGenerationResponse,
  TemplateDocument,
} from '../models/draft';
import { RequestEnum, ResponseEnum, StatusEnum } from '../models/base';
import { ChatMessage, ChatEmailRequest, ChatEmailResponse, ChatEmailResponseChunk, isChatEmailResponseChunk, MessageRole } from '../models/chat';

import { addDocumentContent, getDocumentContentAsText, insertDocxFromBase64 } from '../helper/office';
import { tokenHelper } from '../helper/tokenHelper';
import { getFileType } from '../helper/file';

interface DraftState {
  draftText: string;
  hasGeneratedDraft: boolean;
  isLoadingDraft: boolean;
  draftError: Error;
  contractError: Error;
  templateDocuments: TemplateDocument[];
  contracts: Contract[];
  mandatoryQuestionsSubset: QuestionSubset;
  // base doc upload
  uploadedDocument: TemplateDocument;
  isLoadingUploadedDocument: boolean;
  isUploadedDocumentInQueue: boolean;
  hasUploadedDocument: boolean;
  uploadError: Error;
  mandatoryQuestionsSubsetError: Error;
  // template upload
  templateId?: string;
  templateTitle?: string;
  templateType?: string;
  isLoadingCreateTemplate: boolean;
  isLoadingListContract: boolean;
  isLoadingListMandatoryQuestionsSubset: boolean;
  isLoadingDeleteTemplate: boolean;
  isLoadingListTemplate: boolean;
  
  // Draft Generation
  isGeneratingDraft?: boolean | null;
  isEssentialInformationComplete?: boolean
  draftChunkResponse: DraftDocumentResponseChunk
  stopDraftGenerationResponse: StopDraftGenerationResponse

  // chat
  chatMessages: ChatMessage[];
  chatThreadId?: string;
  
  // Document Session
  isDocumentSessionStarted: boolean;

  uploadedTemplatePath: string | null;

  base64Docx: any | null  //string | null;

  isDocumentInserted: boolean;
}

const initialState: DraftState = {
  draftText: '',
  hasGeneratedDraft: false,
  isLoadingDraft: false,
  draftError: undefined,
  templateDocuments: [],
  contracts: [],
  mandatoryQuestionsSubset: undefined,

  // contract
  contractError: undefined,
  isLoadingListContract: false,

  // base doc
  uploadedDocument: undefined,
  isLoadingUploadedDocument: false,
  isUploadedDocumentInQueue: false,
  hasUploadedDocument: false,
  uploadError: undefined,
  mandatoryQuestionsSubsetError: undefined,
  // template upload
  isLoadingCreateTemplate: false,
  isLoadingListTemplate: false,
  isLoadingListMandatoryQuestionsSubset: false,

  // Draft Generation
  isGeneratingDraft: null,
  isEssentialInformationComplete: false,
  draftChunkResponse: {
    action: ResponseEnum.DraftDocumentResponseChunk,
    status: StatusEnum.Success,
    errorMessage: null,
    response: "",
    document_text: "",
    chunk_index: 1,
    total_chunks: 10,
    is_complete: false,
    task_id: null
  },
  stopDraftGenerationResponse: {
    action: ResponseEnum.StopDraftGenerationResponse,
    status: StatusEnum.Success,
    errorMessage: null,
    response: "",
    task_id: null
  },

  // chat
  chatMessages: [],
  isLoadingDeleteTemplate: false,
  
  // Document Session
  isDocumentSessionStarted: false,

  uploadedTemplatePath: null,

  base64Docx: null,

  isDocumentInserted: false,
};
//----------------------------------------------------------------------
const getAzureBlobClient = (): Promise<BlobServiceClient> => {
  return new Promise((resolve, reject) => {
    tokenHelper.getAccessToken(async token => {
      try {
        const response = await axios.get(
          `${process.env.BACKEND_URL}/sas-token`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const url = response.data.url;
        console.log(url);
        resolve(new BlobServiceClient(url));
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  });
};
//-------------------------------------------------------------------------------------
const getUser = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    tokenHelper.getAccessToken(async token => {
      try {
        console.log('Acquired token:', token);
        const response = await axios.get(
          `${process.env.BACKEND_URL}/users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('User data:', response.data);
        resolve(response.data);
      } catch (error) {
        console.error('Error getting user:', error);
        reject(error);
      }
    });
  });
};

export interface SendDraftRequestPayload {
  template?: TemplateDocument;
  chat_history: ChatMessage[];
  blank_file_path: string;
}

export const sendDraftRequest = createAsyncThunk(
  'draft/sendDraftRequest',
  async ({ template, chat_history, blank_file_path }: SendDraftRequestPayload, { dispatch }) => {
    dispatch(setIsLoadingDraft(true));

    console.log('Sending draft request - template:', template);

    const message: DraftDocumentRequest = {
      action: RequestEnum.DraftDocumentRequest,
      template_id: template?.id,
      chat_history: chat_history,
      blank_file_path: blank_file_path,
    };

    console.log('Created a message to send', message);

    dispatch(sendMessage(message));
  }
);

export const uploadDocument = createAsyncThunk<
  TemplateDocument,
  File,
  { rejectValue: string }
>('draft/uploadDocument', async (file: File, { dispatch, rejectWithValue }) => {
  try {
    const blobServiceClient = await getAzureBlobClient();
    dispatch(setIsLoadingUploadedDocument(true));

    let documentUuid = uuidv4();
    // let user = await getUser();
    // const token = await new Promise(resolve => tokenHelper.getAccessToken(resolve));

    console.log(documentUuid, '>>>>>>>>>>>>');
    // const containerClient = blobServiceClient.getContainerClient('templates');
    const containerClient = blobServiceClient.getContainerClient('documentattachments');

    //upload the byte array to the container
    const blockBlobClient = containerClient.getBlockBlobClient(
      // `${user.id}/${documentUuid}/${file.name}`
      // `${token}/${documentUuid}/${file.name}`
      // `templates/${documentUuid}/${file.name}`
      `${documentUuid}/${file.name}`
    );
    const fileData = await file.arrayBuffer();
    const response = await blockBlobClient.uploadData(new Uint8Array(fileData));

    if (response._response.status === 201) {
      const urlWithoutSAS = blockBlobClient.url.split('?')[0];
      const uploadedDocument: TemplateDocument = {
        id: documentUuid,
        name: file.name,
        path: urlWithoutSAS,
        type: getFileType(file),
        text: '',
      };
      dispatch(setUploadedDocument(uploadedDocument));
      return uploadedDocument;
    } else {
      throw new Error('Failed to upload document');
    }
  } catch (error) {
    dispatch(setUploadError(error));
    console.error(error);
    return rejectWithValue(error.message);
  }
});

export const sendTemplateListRequest = createAsyncThunk(
  'draft/sendTemplateListRequest',
  async (_, { dispatch }) => {
    const message: ListTemplatesRequest = {
      action: RequestEnum.ListTemplatesRequest,
    };

    console.log('Sending a request for templates', message);

    dispatch(sendMessage(message));
    dispatch(setIsLoadingListTemplate(true));
  }
);

export const sendStopDraftGenerationRequest = createAsyncThunk(
  'draft/stopDraftGenerationRequest',
  async (task_id: string, { dispatch })  => {
    const message: StopDraftGenerationRequest = {
      action: RequestEnum.StopDraftGenerationRequest,
      task_id:task_id
    };

    console.log('Sending a request for stop draft generation', message);

    dispatch(sendMessage(message));
  }
);

export const sendContractListRequest = createAsyncThunk(
  'draft/sendContractListRequest',
  async (_, { dispatch }) => {
    const message: ListContractsRequest = {
      action: RequestEnum.ListContractsRequest,
    };

    console.log('Sending a request for contracts', message);

    dispatch(sendMessage(message));
    dispatch(setIsLoadingListContract(true));
  }
);

export const sendMandatoryQuestionsSubsetRequest = createAsyncThunk(
  'draft/sendMandatoryQuestionsSubsetRequest',
  async ({ question_id, current_sequence, earlier_user_response, retained_info, clause = "None" }: { question_id: number, current_sequence: number, earlier_user_response: string, retained_info: any, clause: string }, { dispatch }) => {
    const message: FindAndIterateSubsetQuestionsRequest = {
      action: RequestEnum.FindAndIterateSubsetQuestionsRequest,
      question_id: question_id,
      current_sequence: current_sequence,
      earlier_user_response: earlier_user_response,
      retained_info: retained_info,
      clause: clause
    };

    console.log('Sending a request for mandatory questions subset', message);

    dispatch(sendMessage(message));
    dispatch(setIsLoadingListMandatoryQuestionsSubset(true))
  }
);

export const sendCreateTemplateRequest = createAsyncThunk(
  'draft/sendCreateTemplateRequest',
  async ({ name, type, path }: TemplateDocument, { dispatch }) => {
    const message: CreateTemplateRequest = {
      action: RequestEnum.CreateTemplateRequest,
      document: {
        text: null,
        name: name,
        type: type,
        path: path,
      },
    };

    console.log('Sending create user document request:', message);

    dispatch(setIsUploadedDocumentInQueue(true));
    dispatch(sendMessage(message));
  }
);

export const sendDeleteTemplateRequest = createAsyncThunk(
  'draft/sendDeleteTemplateRequest',
  async (id: string, { dispatch }) => {
    const message: DeleteTemplateRequest = {
      action: RequestEnum.DeleteTemplateRequest,
      template_id: id,
    };

    console.log('Sending delete template request:', message);

    dispatch(setIsLoadingDeleteTemplate(true));
    dispatch(sendMessage(message));
  }
);

export const handleCreateTemplateResponse = createAsyncThunk(
  'draft/handleCreateTemplateResponse',
  async (response: CreateTemplateResponse, { dispatch }) => {
    console.log('Received user document response:', response);

    dispatch(setUploadedDocumentId(response.document_id));
    dispatch(setIsLoadingUploadedDocument(false));
    dispatch(setHasUploadedDocument(true));
    dispatch(setUploadedDocument(null));
    dispatch(sendTemplateListRequest());
  }
);

export const handleDeleteTemplateResponse = createAsyncThunk(
  'draft/handleDeleteTemplateResponse',
  async (response: DeleteTemplateResponse, { dispatch, getState }) => {
    console.log('Received user document response:', response);
    const state = getState() as RootState;
    const { templateDocuments } = state.draft;
    dispatch(
      setTemplateDocuments(
        templateDocuments.filter(
          template => template.id !== response.template_id
        )
      )
    );
    dispatch(setIsLoadingDeleteTemplate(false));
  }
);

export const addAttachMessage = createAsyncThunk(
  'draft/addAttachMessage',
  async (newMessage: ChatMessage, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { chatMessages } = state.draft;
    const { chatThreadId } = state.draft;

    const updatedMessages = [...chatMessages, newMessage];
    dispatch(setChatMessages(updatedMessages));
  }
);

export const removeChatMessage = createAsyncThunk(
  'draft/removeChatMessage',
  async (messageId: String, { dispatch, getState }) => {
    console.log('remove last message with id', messageId);
    const state = getState() as RootState;
    const { chatMessages } = state.draft;

    // Create a new array excluding the last element
    const updatedMessages = chatMessages.slice(0, -1);

    dispatch(setChatMessages(updatedMessages));
  }
);

export const sendChatMessage = createAsyncThunk(
  'draft/sendChatMessage',
  async ({ newMessage, add_current=true }: { newMessage: ChatMessage; add_current: boolean }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { chatMessages } = state.draft;
    const { chatThreadId } = state.draft;

    const updatedMessages = [
      ...chatMessages,
      ...(add_current ? [newMessage] : []), // Conditionally add the current message
      // loading indicator message
      {
        id: uuidv4(),
        role: MessageRole.Assistant,
        message: '...',
        isChunk: true,
        attached : "",
      },
    ];

    dispatch(setChatMessages(updatedMessages));

    const documentText = await getDocumentContentAsText();

    const chatMessageRequest: ChatEmailRequest = {
      action: RequestEnum.ChatEmailRequest,
      thread_id: chatThreadId,
      messages: [...chatMessages, ...(add_current ? [newMessage] : [])],
      open_email_body: documentText,
    };

    console.log('Sending a new chat message:', newMessage);

    dispatch(sendMessage(chatMessageRequest));
  }
);

export const handleChatMessageResponse = createAsyncThunk(
  'draft/handleChatMessageResponse',
  async (
    response: ChatEmailResponse | ChatEmailResponseChunk,
    { dispatch, getState }
  ) => {
    const { draft } = getState() as RootState;
    const { chatMessages } = draft;
    const isChunk = isChatEmailResponseChunk(response);
    const newMessage: ChatMessage = {
      id: uuidv4(),
      role: MessageRole.Assistant,
      message: response.response,
      isChunk,
      attached: '',
    };

    const shouldReplaceLastMessage =
      chatMessages.length > 0 && chatMessages[chatMessages.length - 1].isChunk && chatMessages[chatMessages.length - 1].id == newMessage.id;

    let updatedMessages = shouldReplaceLastMessage
      ? [...chatMessages.slice(0, -1), newMessage]
      : [...chatMessages, newMessage];

    dispatch(setChatMessages(updatedMessages));
    dispatch(setChatThreadId(response.thread_id));
  }
);

export const displayDraftResponse = createAsyncThunk(
  'draft/displayDraftResponse',
  // async (draftText: string, { dispatch }) => {
  //   try
  //   {
  //     if (!draftText) {
  //       console.log('No content to insert');
  //     } else {
  //       try {
  //         await addDocumentContent(draftText);
  //         dispatch(setHasGeneratedDraft(true));
  //       } catch (error) {
  //         dispatch(setDraftError(new Error('Failed to insert text')));
  //       }
  async (draftText: string, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const { base64Docx, isDocumentInserted } = state.draft;
      
      if (base64Docx && !isDocumentInserted) {
        console.log('Attempting to insert document');
        await insertDocxFromBase64(base64Docx);
        dispatch(setIsDocumentInserted(true));
      }
      
      dispatch(setHasGeneratedDraft(true));
      dispatch(setDraftText(draftText));
      dispatch(setIsLoadingDraft(false));
    } catch(error) {
      console.error('Failed to insert text:', error);
      dispatch(setDraftError(new Error('Failed to insert text')));
      dispatch(setIsLoadingDraft(false));
    }
  }
);

export const displayDraftChunk = createAsyncThunk(
  'draft/displayDraftChunk',
  async (draftText: string, { dispatch }) => {
    if (draftText) {
      try {
        await addDocumentContent(draftText);
      } catch (error) {
        dispatch(setDraftError(new Error('Failed to insert text')));
      }
    }
  }
);

export const draftSlice = createSlice({
  name: 'draft',
  initialState,
  reducers: {
    setDraftText: (state, action: PayloadAction<string>) => {
      state.draftText = action.payload;
    },
    setHasGeneratedDraft: (state, action: PayloadAction<boolean>) => {
      state.hasGeneratedDraft = action.payload;
    },
    setIsLoadingDraft: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDraft = action.payload;
    },
    setDraftError: (state, action: PayloadAction<Error>) => {
      state.draftError = action.payload;
    },
    setTemplateDocuments: (
      state,
      action: PayloadAction<TemplateDocument[]>
    ) => {
      state.templateDocuments = action.payload;
    },
    setContracts: (state, action: PayloadAction<Contract[]>) => {
      state.contracts = action.payload;
    },
    setMandatoryQuestionsSubset: (
      state,
      action: PayloadAction<QuestionSubset>
    ) => {
      state.mandatoryQuestionsSubset = action.payload;
    },
    // BASE DOCUMENT UPLOAD
    setUploadedDocumentId: (state, action: PayloadAction<string>) => {
      state.uploadedDocument.id = action.payload;
    },
    setUploadedDocument: (state, action: PayloadAction<TemplateDocument>) => {
      state.uploadedDocument = action.payload;
    },
    setHasUploadedDocument: (state, action: PayloadAction<boolean>) => {
      state.hasUploadedDocument = action.payload;
    },
    setIsUploadedDocumentInQueue: (state, action: PayloadAction<boolean>) => {
      state.isUploadedDocumentInQueue = action.payload;
    },
    setIsLoadingUploadedDocument: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUploadedDocument = action.payload;
    },
    setUploadError: (state, action: PayloadAction<Error>) => {
      state.uploadError = action.payload;
    },
    // TEMPLATE UPLOAD
    setTemplateId: (state, action: PayloadAction<string>) => {
      state.templateId = action.payload;
    },
    setTemplateTitle: (state, action: PayloadAction<string>) => {
      state.templateTitle = action.payload;
    },
    setTemplateType: (state, action: PayloadAction<string>) => {
      state.templateType = action.payload;
    },
    setIsLoadingCreateTemplate: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCreateTemplate = action.payload;
    },
    setIsLoadingDeleteTemplate: (state, action: PayloadAction<boolean>) => {
      state.isLoadingDeleteTemplate = action.payload;
    },
    setIsLoadingListTemplate: (state, action: PayloadAction<boolean>) => {
      state.isLoadingListTemplate = action.payload;
    },
    // CHAT
    setChatMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      state.chatMessages = action.payload;
    },
    setChatThreadId: (state, action: PayloadAction<string>) => {
      state.chatThreadId = action.payload;
    },
    // CONTRACT
    setIsLoadingListContract: (state, action: PayloadAction<boolean>) => {
      state.isLoadingListContract = action.payload;
    },
    setContractError: (state, action: PayloadAction<Error>) => {
      state.contractError = action.payload;
    },
    // MANDATORY QUESTIONS SUBSET
    setIsLoadingListMandatoryQuestionsSubset: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLoadingListMandatoryQuestionsSubset = action.payload;
    },
    setMandatoryQuestionsSubsetError: (state, action: PayloadAction<Error>) => {
      state.mandatoryQuestionsSubsetError = action.payload;
    },
    // Draft Generation
    setIsGeneratingDraft: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isGeneratingDraft = action.payload;
    },
    setIsEssentialInformationComplete: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isEssentialInformationComplete = action.payload;
    },
    setDraftResponseChunk: (
      state,
      action: PayloadAction<DraftDocumentResponseChunk>
    ) => {
      state.draftChunkResponse = action.payload;
    },

    setIsDocumentSessionsStarted: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDocumentSessionStarted = action.payload;
    },

    setUploadedTemplatePath: (state, action: PayloadAction<string | null>) => {
      state.uploadedTemplatePath = action.payload;
    },
    setStopDraftGenerationResponse: (state, action: PayloadAction<StopDraftGenerationResponse | null>) => {
      state.stopDraftGenerationResponse = action.payload;
    },
    setBase64Docx: (state, action: PayloadAction<any>) => {
      state.base64Docx = action.payload;
    },
    setIsDocumentInserted: (state, action: PayloadAction<boolean>) => {
      state.isDocumentInserted = action.payload;
    },
  },
});

export const {
  setDraftText,
  setHasGeneratedDraft,
  setIsLoadingDraft,
  setDraftError,
  setBase64Docx,
  setTemplateDocuments,
  // base doc
  setUploadedDocumentId,
  setUploadedDocument,
  setIsUploadedDocumentInQueue,
  setHasUploadedDocument,
  setIsLoadingUploadedDocument,
  setUploadError,
  // upload template
  setTemplateId,
  setIsLoadingCreateTemplate,
  // chat
  setChatMessages,
  setChatThreadId,
  setIsLoadingDeleteTemplate,
  setIsLoadingListTemplate,
  // contracts
  setContracts,
  setIsLoadingListContract,
  setContractError,
  // mandatory questions
  setMandatoryQuestionsSubset,
  setIsLoadingListMandatoryQuestionsSubset,
  setMandatoryQuestionsSubsetError,
  // Draft Generation
  setIsGeneratingDraft,
  setIsEssentialInformationComplete,
  setDraftResponseChunk,
  setStopDraftGenerationResponse,
  // Document Session
  setIsDocumentSessionsStarted,
  setUploadedTemplatePath,
  setIsDocumentInserted
} = draftSlice.actions;

export default draftSlice.reducer;