export interface DocumentData {
    styles: any;
    content: any;
    templateBase64: any;
}

interface StyleFormat {
    font?: {
        name: string;
        size: number;
        bold: boolean;
        italic: boolean;
    };
    paragraph?: {
        alignment: 'CENTER' | 'LEFT' | 'JUSTIFIED' | 'RIGHT';
        space_before: number;
        space_after: number;
    };
}

export class DocumentService {
    private static NUMBERING = [];

    static async buildDocument(documentData: DocumentData): Promise<void> {
        try {
            await Word.run(async (context) => {
                console.log("Starting document build...");
                
                // Validate input data
                if (!documentData?.content || !documentData?.styles || !documentData?.templateBase64) {
                    throw new Error("Invalid document data structure");
                }
                
                // Clear existing content
                context.document.body.clear();
                await context.sync();
                console.log("Document cleared");

                // Apply styles first
                if (Object.keys(documentData.styles).length > 0) {
                    try {
                        await this.applyStyles(context, documentData.styles, documentData.templateBase64);
                        await context.sync();
                        console.log("Styles applied successfully");
                    } catch (err) {
                        console.error("Style application error:", err);
                        // Continue even if styles fail
                    }
                }

                // Add content
                if (documentData.content) {
                    try {
                        await this.addContent(context, documentData.content);
                        await context.sync();
                        console.log("Content added successfully");
                    } catch (err) {
                        console.error("Content addition error:", err);
                        throw err;
                    }
                }

                await context.sync();
                console.log("Document build completed");
            });
        } catch (error) {
            console.error('Error building document:', error);
            console.error('Document data:', JSON.stringify(documentData, null, 2));
            // throw error;
        }
    }

    private static async applyStyles(context: Word.RequestContext, styles: { 
        headings?: Record<string, StyleFormat>;
        normal?: StyleFormat;
        lists?: StyleFormat;
        recitals?: StyleFormat; }, templateBase64: string): Promise<void> {
        try {
            let fontName = 'Arial';
            let minfontSize = 9;
            let maxfontSize = 14;
            if (styles.headings) {
                const firstHeading = Object.keys(styles.headings)[0];
                fontName = styles.headings[firstHeading].font.name;
                // minfontSize = styles.headings['1'].font.size;
                maxfontSize = styles.headings[firstHeading].font.size;
                console.log("Font name:", fontName);
            }
            // Insert numbering definition first
            const dummy = templateBase64
            // await this.insertNumberingDefinition(context, templateBase64);
            
            // Create base style first
            if (styles.normal) {
                let normalStyle;
                try {
                    normalStyle = context.document.getStyles().getByNameOrNullObject('CustomNormal');
                    await context.sync();
                    if (!normalStyle.isNullObject) {
                        normalStyle.delete();
                        await context.sync();
                    }
                    normalStyle = context.document.addStyle('CustomNormal', Word.StyleType.paragraph);
                } catch (error) {
                    normalStyle = context.document.addStyle('CustomNormal', Word.StyleType.paragraph);
                }
                await context.sync();
                
                if (styles.normal.font) {
                    normalStyle.font.name = fontName;  // styles.normal.font.name;
                    normalStyle.font.size = Math.max(minfontSize, Math.min(maxfontSize, styles.normal.font.size));
                    normalStyle.font.bold = styles.normal.font.bold;
                    normalStyle.font.italic = styles.normal.font.italic;
                }
                if (styles.normal.paragraph) {
                    normalStyle.paragraphFormat.lineSpacing = styles.normal.font.size * 1.5; // Set line height to double the font size
                    normalStyle.paragraphFormat.alignment = Word.Alignment.justified;
                        // styles.normal.paragraph.alignment === 'CENTER' ? Word.Alignment.centered : Word.Alignment.justified;
                    normalStyle.paragraphFormat.spaceAfter = 4; //styles.normal.paragraph.space_after === 0 ? 120 : styles.normal.paragraph.space_after;
                    normalStyle.paragraphFormat.spaceBefore = 4; //styles.normal.paragraph.space_before === 0 ? 120 : styles.normal.paragraph.space_before;
                }
                await context.sync();
            }

            // Then create heading styles
            if (styles.headings) {
                for (const [level, style] of Object.entries(styles.headings)) {
                    try {
                        const styleName = `Custom${level.toUpperCase()}`;
                        let wordStyle;
                        try {
                            wordStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                            await context.sync();
                            if (!wordStyle.isNullObject) {
                                wordStyle.delete();
                                await context.sync();
                            }
                            wordStyle = context.document.addStyle(styleName, Word.StyleType.paragraph);
                        } catch (error) {
                            wordStyle = context.document.addStyle(styleName, Word.StyleType.paragraph);
                        }
                        await context.sync();
                        
                        if (style.font) {
                            wordStyle.font.name = fontName;  // style.font.name;
                            wordStyle.font.size = style.font.size;
                            wordStyle.font.bold = true; // style.font.bold;  TODO: make it dynamic
                            wordStyle.font.italic = style.font.italic;
                        }
                        if (style.paragraph) {
                            wordStyle.paragraphFormat.lineSpacing = style.font.size * 1.5;
                            wordStyle.paragraphFormat.alignment = 
                                style.paragraph.alignment === 'CENTER' ? Word.Alignment.centered : Word.Alignment.justified;
                            wordStyle.paragraphFormat.spaceAfter = Math.max(5, style.paragraph.space_after);
                            wordStyle.paragraphFormat.spaceBefore = Math.max(5, style.paragraph.space_before);
                        }
                        await context.sync();
                    } catch (err) {
                        console.warn(`Failed to create style for ${level}:`, err);
                    }
                }
            }

            // Apply list formatting
            if (styles.lists) {
                for (const [level, style] of Object.entries(styles.lists)) {
                    const listStyleName = `CustomList${level}`;
                    try {
                        // Check if style exists and delete it
                        const existingStyle = context.document.getStyles().getByNameOrNullObject(listStyleName);
                        await context.sync();
                        if (!existingStyle.isNullObject) {
                            existingStyle.delete();
                            await context.sync();
                        }
                        
                        // Create new style
                        const listStyle = context.document.addStyle(listStyleName, Word.StyleType.paragraph);
                        await context.sync();

                        listStyle.baseStyle = "List Paragraph";
                        await context.sync();

                        // Apply font properties
                        if (style.font) {
                            listStyle.font.name = fontName;  // style.font.name;
                            listStyle.font.size = Math.max(minfontSize, Math.min(maxfontSize, style.font.size));
                            listStyle.font.bold = style.font.bold;
                            listStyle.font.italic = style.font.italic;
                        }
                        // Apply paragraph properties
                        if (style.paragraph) {
                            listStyle.paragraphFormat.lineSpacing = style.font.size * 1.5;
                            listStyle.paragraphFormat.alignment = Word.Alignment.justified;
                                // style.paragraph.alignment === 'LEFT' ? Word.Alignment.left : Word.Alignment.justified;
                            // listStyle.paragraphFormat.leftIndent = 18 * parseInt(level);  // Indent based on level
                            // listStyle.paragraphFormat.firstLineIndent = -9;
                            listStyle.paragraphFormat.spaceAfter = 4;  // style.paragraph.space_after || 0;
                            listStyle.paragraphFormat.spaceBefore = 4;  // style.paragraph.space_before || 0;
                        }

                        if (style.numbering) {
                            // Define numbering for lists
                            // const numberingStyle = style.numbering;
                            // this.NUMBERING = numberingStyle.map((style, index) => ({
                            //     level: index,
                            //     numbering: style.type,
                            //     indent: style.indent,
                            //     hanging: style.hanging
                            // }));
                        }
                            
                        await context.sync();
                    } catch (err) {
                        console.warn(`Failed to create style for ${level}:`, err);
                    }
                }
            }
        } catch (error) {
            console.error('Error applying styles:', error);
            // Continue with document creation even if styles fail
        }
    }
    
    private static async insertNumberingDefinition(context: Word.RequestContext, base64Template: string): Promise<void> {
        try {

            const body = context.document.body;
            body.clear();
            await context.sync();
        
            // Insert base64 content
            body.insertFileFromBase64(base64Template, Word.InsertLocation.replace);
            await context.sync();


            // const body = context.document.body;
            // body.clear();
            // await context.sync();
        
            // // Insert base64 content
            // await body.insertFileFromBase64(base64Template, Word.InsertLocation.replace);
            // await context.sync();

            // // Load the document to ensure it's properly initialized
            // context.document.load('body');
            // await context.sync();

            // // Set document to read-write mode explicitly
            // Office.context.document.mode = Office.DocumentMode.ReadWrite;
            
            console.log("Template inserted and initialized successfully");
        } catch (error) {
            console.error("Error inserting numbering definition:", error);
            // Continue even if numbering definition fails
        }
    }

    private static async addContent(context: Word.RequestContext, content: any): Promise<void> {
        try {
            // Add title
            if (content.title?.text) {
                const titleParagraph = context.document.body.insertParagraph(content.title.text.toUpperCase(), Word.InsertLocation.start);
                let styleName = content.title.style || 'CustomH1';   
                const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                await context.sync();
                if (customStyle.isNullObject) {
                    styleName = 'CustomNormal';
                }
                titleParagraph.style = styleName;  // content.title.style || 'CustomH1';
                await context.sync();
            }

            // Add subtitle
            if (content.subtitle?.text) {
                const subtitleParagraph = context.document.body.insertParagraph(content.subtitle.text.toUpperCase(), Word.InsertLocation.end);
                let styleName = content.subtitle.style || 'CustomH2';   
                const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                await context.sync();
                if (customStyle.isNullObject) {
                    styleName = 'CustomNormal';
                }
                subtitleParagraph.style = styleName;  // content.subtitle.style || 'CustomH2';
                await context.sync();
            }

            // Add sections
            if (Array.isArray(content.sections)) {
                for (const section of content.sections) {
                    await this.addSection(context, section);
                    await context.sync();
                }
            }
        } catch (error) {
            console.error('Error adding content:', error);
            // throw error;
        }
    }

    private static async addSection(context: Word.RequestContext, section: any): Promise<void> {
        try {
            // Add section title if it exists
            const isContentEmpty = section.content.length === 0;
            let isNotRecital = false;
            if (section.title?.text) {
                const titleParagraph = context.document.body.insertParagraph(section.title.text, Word.InsertLocation.end);
                isNotRecital = /^\d+/.test(section.title.text);
                let styleName = (!isContentEmpty && isNotRecital)? section.title.style || 'CustomH3' : 'CustomNormal';
                const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                await context.sync();
                if (customStyle.isNullObject) {
                    styleName = 'CustomNormal';
                }
                titleParagraph.style = styleName;  // section.title.style || 'CustomH3';
                await context.sync();
            }

            // Add section content if it exists
            if (Array.isArray(section.content) && !isContentEmpty) {

                for (const contentItem of section.content) {
                    try {
                        await this.addContentItem(context, contentItem, isNotRecital);
                        await context.sync();
                    } catch (error) {
                        console.error('Error adding content item in section:', error);
                        // Continue with other content items even if one fails
                    }
                }
            }
        } catch (error) {
            console.error('Error adding section:', error);
            // throw error;
        }
    }

    private static async addTextWithLinks(context: Word.RequestContext, paragraph: Word.Paragraph, text: string): Promise<void> {
        try {
            // Find all URL patterns and following words: (<url>) next_word
            const parts = text.split(/(\(<https?:\/\/[^>]+>\)\s*\w+)/);

            for (const part of parts) {
                if (part.startsWith('(<http') && part.includes('>)')) {
                    // Extract URL and following word
                    const urlPattern = part.match(/\(<(https?:\/\/[^>]+)>\)\s*(\w+)/);
                    if (urlPattern) {
                        const url = urlPattern[1];
                        const linkText = urlPattern[2];

                        // Add space before link
                        paragraph.insertText(' ', Word.InsertLocation.end);
                        
                        // Add hyperlink
                        await this.addHyperlink(context, paragraph, url, linkText);
                    } else {
                        // Fallback: just add the text as-is
                        paragraph.insertText(part.trim(), Word.InsertLocation.end);
                    }
                } else {
                    // Add regular text
                    if (part.trim()) {
                        paragraph.insertText(part.trim(), Word.InsertLocation.end);
                    }
                }
            }
            await context.sync();
        } catch (error) {
            console.error('Error adding text with links:', error);
        }
    }

    private static async addHyperlink(context: Word.RequestContext, paragraph: Word.Paragraph, url: string, text: string): Promise<void> {
        try {
            // Create hyperlink
            const range = paragraph.insertText(text, Word.InsertLocation.end);
            range.hyperlink = url;
            
            // Apply hyperlink styling
            range.font.color = '0000FF';  // Blue color
            // range.font.underline = true;
            
            await context.sync();
        } catch (error) {
            console.error('Error adding hyperlink:', error);
        }
    }

    private static async addContentItem(context: Word.RequestContext, contentItem: any, isNotRecital: boolean = false, existingList: Word.List = null): Promise<void> {
        try {
            if (contentItem.type === 'list') {
                let list: Word.List;
                if (existingList) {
                    console.log("USING EXISTING LIST");
                    list = existingList;
                }

                // Create first paragraph to start the list
                const firstItem = contentItem.items[0];
                let paragraphText = "";  // firstItem.label;
                if (firstItem.paragraphs.length > 0) {
                    for (let j = 0; j < firstItem.paragraphs.length; j++) {
                        paragraphText += firstItem.paragraphs[j];
                        if (j != firstItem.paragraphs.length - 1) {
                            paragraphText += "\r\n<br><br>";
                        }
                    }
                }
                
                let firstParagraph: Word.Paragraph;
                if (existingList) {
                    firstParagraph = existingList.insertParagraph("", Word.InsertLocation.end);
                } else {
                    firstParagraph = context.document.body.insertParagraph(
                        "", 
                        Word.InsertLocation.end
                    );
                }
                // await this.addTextWithLinks(context, firstParagraph, paragraphText);
                // firstParagraph.style = `CustomList${contentItem.items[0].level}`;
                // await context.sync();

                // add other text
                firstParagraph.insertHtml(paragraphText, Word.InsertLocation.end);
                await context.sync();

                // Start new list with first paragraph
                if (!existingList) {
                    list = firstParagraph.startNewList();
                }
                let styleName = `CustomList${contentItem.items[0].level}`;   
                const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                await context.sync();
                if (customStyle.isNullObject) {
                    styleName = 'CustomNormal';
                }
                // firstParagraph.style = styleName;
                firstParagraph.listItem.level = contentItem.items[0].level; // non-zero based level
                await context.sync();

                // // Insert numbering definition
                // await this.insertNumberingDefinition(context, contentItem.items[0].numbering);
                // await context.sync();
                if (!existingList) {
                    if (isNotRecital) {
                        // Set numbering for first level (upperLetter)
                        list.setLevelNumbering(0, Word.ListNumbering.arabic);
                        // Set numbering for second level (lowerLetter)
                        list.setLevelNumbering(1, Word.ListNumbering.upperRoman);
                        // Set numbering for third level (decimal)
                        list.setLevelNumbering(2, Word.ListNumbering.lowerLetter);
                        // Set numbering for fourth level (lowerRoman)
                        list.setLevelNumbering(3, Word.ListNumbering.lowerRoman);
                    } else {
                        // Set numbering for first level (upperLetter)
                        list.setLevelNumbering(0, Word.ListNumbering.arabic);
                        // Set numbering for second level (lowerLetter)
                        list.setLevelNumbering(1, Word.ListNumbering.upperLetter);
                        // Set numbering for third level (decimal)
                        list.setLevelNumbering(2, Word.ListNumbering.lowerLetter);
                        // Set numbering for fourth level (lowerRoman)
                        list.setLevelNumbering(3, Word.ListNumbering.lowerRoman);
                    }
                    await context.sync();

                    // Set indents for first level
                    list.setLevelIndents(0, 9, -9);  // (level, start, hanging)
                    // Set indents for second level
                    list.setLevelIndents(1, 9, -9);
                    // Set indents for third level
                    list.setLevelIndents(2, 18, -9);
                    // Set indents for fourth level
                    list.setLevelIndents(3, 27, -9);
                    await context.sync();
                }

                // Handle nested lists if they exist
                if (firstItem.nested_list) {
                    await this.addContentItem(context, firstItem.nested_list, isNotRecital, list);
                }

                // Add remaining items to the list
                for (let i = 1; i < contentItem.items.length; i++) {
                    const item = contentItem.items[i];

                    let paragraphText = ""; //item.label;
                    if (item.paragraphs.length > 0) {
                        for (let j = 0; j < item.paragraphs.length; j++) {
                            paragraphText += item.paragraphs[j];
                            if (j != item.paragraphs.length - 1) {
                                paragraphText += "\r\n<br><br>";
                            }   
                        }
                    }

                    const listParagraph = list.insertParagraph("", Word.InsertLocation.end);
                    await context.sync();

                    // add other text
                    listParagraph.insertHtml(paragraphText, Word.InsertLocation.end);
                    await context.sync();
                    // await this.addTextWithLinks(context, labelParagraph, paragraphText);
                    let styleName = `CustomList${item.level}`;   
                    const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                    await context.sync();
                    if (customStyle.isNullObject) {
                        styleName = 'CustomNormal';
                    }
                    // labelParagraph.style = styleName;
                    listParagraph.listItem.level = item.level; // non-zero based level
                    await context.sync();

                    // Handle nested lists if they exist
                    if (item.nested_list) {
                        await this.addContentItem(context, item.nested_list, isNotRecital, list);
                    }
                }
            } else if (contentItem.type === 'text' && contentItem.element){  // && contentItem.element) {
                const paragraph = context.document.body.insertParagraph(contentItem.element.text, Word.InsertLocation.end);
                let styleName = 'CustomNormal'  // contentItem.element.style || 'CustomNormal';
                const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                await context.sync();
                if (customStyle.isNullObject) {
                    styleName = 'CustomNormal';
                }
                paragraph.style = styleName;
                // await this.addTextWithLinks(context, paragraph, contentItem.element.text);
                await context.sync();
            } else if (contentItem.type === 'table') {
                await this.addTable(context, contentItem);
                await context.sync();
            }
        } catch (error) {
            console.error('Error adding content item:', error);
            // Continue with other content items even if one fails
        }
    }

    private static async addTable(context: Word.RequestContext, tableItem: any): Promise<void> {
        const rows = tableItem.content.rows;
        const table = context.document.body.insertTable(rows.length, rows[0].length, Word.InsertLocation.end);
        
        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
            for (let colIndex = 0; colIndex < rows[rowIndex].length; colIndex++) {
                const cell = rows[rowIndex][colIndex];
                table.getCell(rowIndex, colIndex).body.insertParagraph(cell.text, Word.InsertLocation.end);
                let styleName = 'CustomNormal'  // cell.style || 'CustomNormal';
                const customStyle = context.document.getStyles().getByNameOrNullObject(styleName);
                await context.sync();
                if (customStyle.isNullObject) {
                    styleName = 'CustomNormal';
                }
                table.getCell(rowIndex, colIndex).body.paragraphs[0].style = styleName;
            }
        }
    }
}