import * as React from 'react';
import styles from './Layout.module.css';
import { Image, Link, Toaster } from '@fluentui/react-components';
import { tokenHelper } from '../../helper/tokenHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLogin, setTokenState } from '../../store/socketSlice';
import { setChatMessages, setReviewStepNumber } from '../../store/reviewSlice';
import {
  setChatMessages as setQcounselChatMessages,
  setStopStreaming,
} from '../../store/qcounselSlice';
import { initApp } from '../../store/rootReducer';
import {
  checkDocumentHasContent,
  loadWordDocument,
  setCurrentTab,
  setIsAnalyzing,
} from '../../store/officeSlice';
import { AppDispatch, RootState } from '../../store/store';
import { ChatContainer } from '../features/qcounsel/QCounsel';
import { HomeTabs, ReviewSteps } from '../features/review/components/const';
import {
  setLoadReviewSummary,
  setLoadSummary,
} from '../../store/documentSlice';
import {
  getDisplayDraftFlag,
  getDisplayGlobalChangesFlag,
} from '../../store/featureFlagSlice';
import { setIsStreaming } from '../../store/qcounselSlice';

// Initialize Stripe with your publishable key
export const GLOBAL_TOAST_ID = 'global';
interface LayoutProps {
  header: React.ReactNode;
  footer: React.ReactNode;
  children: React.ReactNode;
  styles?: React.CSSProperties;
}
export const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const isConnected = useSelector(
    (state: RootState) => state.socket.isConnected
  );
  React.useEffect(() => {
    tokenHelper.getAccessToken(
      _ => {},
      false,
      accesstoken => {
        if (accesstoken) {
          console.log('isconnected', isConnected);
          dispatch(setTokenState(accesstoken));
          dispatch(setIsLogin(true));
          dispatch(initApp());
          dispatch(checkDocumentHasContent());
          //review section
          dispatch(setChatMessages([])); // for review chating
          dispatch(setCurrentTab(HomeTabs.Home));
          dispatch(setReviewStepNumber(ReviewSteps.chat));
          //for summary section
          dispatch(setLoadReviewSummary(false));
          dispatch(setLoadSummary(false));
          //for qcounsel
          dispatch(setIsStreaming(false));
          dispatch(setStopStreaming(false));
          dispatch(setQcounselChatMessages([]));
        } else {
          dispatch(setIsLogin(false));
          console.log('you should login');
        }
      }
    );
  }, []);
  React.useEffect(() => {
    if (isConnected) {
      dispatch(loadWordDocument());
      dispatch(setIsAnalyzing(false));
      dispatch(getDisplayDraftFlag({}));
      dispatch(getDisplayGlobalChangesFlag({}));
    }
  }, [isConnected]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>{props.header}</div>
      <div className={styles.main}>{props.children}</div>
      <div className={styles.footer}>
        {props.footer}
        <ChatContainer />
        <div className={styles.footerPart}>
          <Image src="/assets/right.png" />
          <p>
            Your data is private and secure <Link href="#">Learn more</Link>
          </p>
        </div>
      </div>
      <Toaster toasterId={GLOBAL_TOAST_ID} />
    </div>
  );
};
